// Cleans up the input from the user

export const cleanUpInput = (input) => {
  if (!input) return "";
  // Replace all types of line breaks with a single space removes whitespaces etc
  const cleanedInput = input.replace(/[\s\r\n]+/g, " ");

  // Trim the leading and trailing spaces
  const output = cleanedInput.trim();

  return output;
};
