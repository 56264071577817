import React, { useState, useEffect, useCallback } from "react";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Login from "./pages/Login";
import styles from "./App.module.css";
import Settings from "./pages/Settings";
import Feedback from "./pages/Feedback";
import TestHistory from "./pages/TestHistory";
import { setCSSColors } from "./utils/Colors";
import AdminPortal from "./pages/AdminPortal";
import ViewTest from "./pages/mockTest/ViewTest";
import AskDoubt from "./pages/doubts/AskDoubts";
import DoubtsHistory from "./pages/DoubtsHistory";
import CreateTest from "./pages/mockTest/CreateTest";
import QuestionDisplay from "./pages/QuestionDisplay";
import Onboarding from "./pages/onboarding/Onboarding";
import { UserContextProvider } from "./utils/UserContext";
import TestScreen from "../src/pages/mockTest/TestScreen";
import PreviousYearExam from "./pages/mockTest/PreviousYearExam";
import ViewPreviousExam from "./pages/mockTest/ViewPreviousExam";
import { readOnceFromFirebase, useSyncWithFirebase } from "./utils/firebase";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { deleteLoginCodeLocally, getLoginCodeLocally } from "./functions/loginCodeFunctions";
import TopperNotes from "./pages/TopperNotes";

const localVersion = 7;

const PageWrapper = () => {
  const navigate = useNavigate();
  const loginCode = getLoginCodeLocally();
  // console.log("Login Code in App.js", loginCode);

  // Header Logic
  // const activePage = useLocation().pathname;
  // const headerExcludePages = [
  //   "/onboarding",
  //   "/login",
  //   "/question-display",
  //   "/test-screen",
  //   "/view-test",
  //   "/aiguru-admin-portal",
  //   "/view-previous-exam",
  //   "/studentai-admin-portal",
  //   "/",
  // ];
  // const showHeader = !headerExcludePages.includes(activePage);

  // Getting loginTimeStamp from Firebase
  const [loginTimeStamp, setLoginTimeStamp] = useState(null);
  const isPremiumUser = loginTimeStamp > new Date().getTime();
  useSyncWithFirebase({
    path: loginCode ? `allowedUser/${loginCode}` : null,
    setFunction: setLoginTimeStamp,
  });

  // console.log("App Screen", loginTimeStamp, isPremiumUser, new Date().getTime());

  const checkOnboarding = useCallback(async () => {
    const isOnboardingComplete = await readOnceFromFirebase({ path: `users/${loginCode}/name` });
    // Handle Onboarding
    if (!isOnboardingComplete) {
      navigate("/onboarding");
    }
  }, [loginCode, navigate]);

  // Fetching Login Code locally
  useEffect(() => {
    // Logging out user if not premium user now
    if (!isPremiumUser && loginCode && !!loginTimeStamp) {
      deleteLoginCodeLocally();
    }

    // If loginCode is false, navigate to the login page
    if (!loginCode) {
      navigate("/login");
    } else {
      checkOnboarding();
    }
  }, [navigate, loginTimeStamp, loginCode, isPremiumUser, checkOnboarding]);

  // Refreshing the website if updates are pushed
  const [version, setVersion] = useState(0);
  useSyncWithFirebase({ path: "version", setFunction: setVersion });
  useEffect(() => {
    if (version > localVersion) {
      window.location.reload();
    }
  }, [version]);

  // Set CSS variables on app load
  useEffect(() => {
    setCSSColors();
  }, []);

  return (
    <UserContextProvider loginCode={loginCode}>
      <div className={styles.container}>
        {/* {isMobileDevice && showHeader && <Header />} */}

        <Routes>
          {/* General */}
          <Route path="/" element={<Home />} />
          <Route path="/ask-doubt" element={<AskDoubt />} />
          <Route path="/help" element={<Help />} />
          <Route path="/login" element={<Login />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/doubts-history" element={<DoubtsHistory />} />
          <Route path="/topper-notes" element={<TopperNotes />} />

          {/* Tests */}
          <Route path="/view-test" element={<ViewTest />} />
          <Route path="/test-history" element={<TestHistory />} />
          <Route path="/test" element={<CreateTest />} />
          <Route path="/test-screen" element={<TestScreen />} />
          <Route path="/question-display" element={<QuestionDisplay />} />
          <Route path="/view-previous-exam" element={<ViewPreviousExam />} />
          <Route path="/previous-year-exams" element={<PreviousYearExam />} />

          {/* Admin Portals */}
          <Route path="/aiguru-admin-portal" element={<AdminPortal institute="aiguru" />} />
          <Route path="/studentai-admin-portal" element={<AdminPortal institute="studentai" />} />
          <Route path="/puranmurti-admin-portal" element={<AdminPortal institute="puranmurti" />} />
        </Routes>
      </div>
    </UserContextProvider>
  );
};

// Complete App
const App = () => {
  return (
    <Router>
      <PageWrapper />
    </Router>
  );
};

export default App;
