import React, { useState, useEffect } from "react";
import Colors from "../utils/Colors";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../functions/isEmpty";
import styles from "../styles/Login.module.css";
import useEnterPressed from "../hooks/useEnterPressed";
// import BrandIdentity from "../components/BrandIdentity";
import PrimaryButton from "../components/PrimaryButton";
import { getWhatsappUrl } from "../functions/getWhatsappUrl";
import { setLoginCodeLocally } from "../functions/loginCodeFunctions";
import { getDeviceFingerprint } from "../functions/getDeviceFingerprint";
import { readOnceFromFirebase, updateOnFirebase } from "../utils/firebase";

const ContactUsPopup = ({ setShowCompletePopup }) => {
  const studentAIUrl = getWhatsappUrl("Please start my Student AI 3-day Free Trial");
  const puranMurtiAIUrl = getWhatsappUrl("Please start my Puran Murti AI 3-day Free Trial");
  const aceInstituteUrl = getWhatsappUrl("I want to buy AI Guru Premium", 918700032890);
  const notListedUrl = getWhatsappUrl(
    "I am part of another School, Please start my 3-day Free Trial"
  );

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <div className={styles.header}>
          <h4 className={styles.popupHeading}>Select your Institute</h4>
          <button className={styles.closeButton} onClick={() => setShowCompletePopup(false)}>
            ✖
          </button>
        </div>
        <div className={styles.subjectList}>
          <div className={styles.subjectRow}>
            <span className={styles.subjectName}>Puran Murti</span>
            <PrimaryButton
              name="Start"
              mobileWidth="50%"
              mobileFontSize="0.7em"
              onClick={() => window.open(puranMurtiAIUrl, "_blank")}
            />
          </div>
          <div className={styles.subjectRow}>
            <span className={styles.subjectName}>Student AI</span>
            <PrimaryButton
              name="Start"
              mobileWidth="50%"
              mobileFontSize="0.7em"
              onClick={() => window.open(studentAIUrl, "_blank")}
            />
          </div>
          <div className={styles.subjectRow}>
            <span className={styles.subjectName}>Ace Institute</span>
            <PrimaryButton
              name="Start"
              mobileWidth="50%"
              mobileFontSize="0.7em"
              onClick={() => window.open(aceInstituteUrl, "_blank")}
            />
          </div>
          <div className={styles.subjectRow}>
            <span className={styles.subjectName}>Not Listed ?</span>
            <PrimaryButton
              name="Start"
              mobileWidth="50%"
              mobileFontSize="0.7em"
              onClick={() => window.open(notListedUrl, "_blank")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Login = () => {
  const devicesLimit = 2;
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [devicesArray, setDevicesArray] = useState([]);
  const [inputLoginCode, setInputLoginCode] = useState("");
  const [deviceFingerprint, setDeviceFingerprint] = useState("");
  const [showCompletePopup, setShowCompletePopup] = useState(false);

  // Handline Fingerprint and Devices
  useEffect(() => {
    const handleFingerprint = async () => {
      // Getting Local Fingerprint
      const fingerprint = await getDeviceFingerprint();
      setDeviceFingerprint(fingerprint);

      // Checking Device Limits
      const devices = await readOnceFromFirebase({ path: `users/${inputLoginCode}/devices` });
      const devicesArray = isEmpty(devices) ? [] : Object.keys(devices);
      setDevicesArray(devicesArray);
    };

    handleFingerprint();
  }, [inputLoginCode]);

  // Fading Alert Message
  useEffect(() => {
    setTimeout(() => setAlertMessage(""), 5000);
  }, [alertMessage]);

  const loginHandler = async () => {
    const loginTimeStamp = await readOnceFromFirebase({ path: `allowedUser/${inputLoginCode}` });
    const isPremiumUser = loginTimeStamp > new Date().getTime();
    let isDevicesExceeded =
      devicesArray.length >= devicesLimit && !devicesArray.includes(deviceFingerprint);

    // Adding Exception to self login Codes for development
    if (["harshit", "mukul"].includes(inputLoginCode)) {
      isDevicesExceeded = false;
    }

    // console.log("Login Screen", loginTimeStamp, isPremiumUser, new Date().getTime());

    // If Premium User
    if (isPremiumUser && !isDevicesExceeded) {
      // Storing Device Fingerprint on Firebase
      const updates = {};
      updates[`users/${inputLoginCode}/devices/${deviceFingerprint}`] = true;
      updateOnFirebase({ updates });

      // Storing Locally
      setLoginCodeLocally(inputLoginCode);
      navigate("/");
    }

    // Devices Exceeded
    else if (isPremiumUser && isDevicesExceeded) {
      setAlertMessage(`You can only login to ${devicesLimit} devices, Contact Support for help.`);
    }

    // Login Code Expired
    else if (!isPremiumUser && !!loginTimeStamp) {
      setAlertMessage("Login Code Expired, Buy Premium to use app.");
    }

    // Wrong Login Code
    else {
      setInputLoginCode("");
      setAlertMessage("Invalid Code, Please contact your institute");
    }
  };

  // Handle Enter Press
  useEnterPressed(loginHandler);

  return (
    <div className={styles.onboardingContainer}>
      {/* <BrandIdentity /> */}
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>Welcome</h2>

        {/* Login */}
        <input
          type="text"
          value={inputLoginCode}
          placeholder="Login Code"
          className={styles.topicInput}
          onChange={(e) => setInputLoginCode(e.target.value)}
        />

        {/* Alert */}
        {alertMessage && <p style={{ color: Colors.errorColor }}>{alertMessage}</p>}

        {/* Login Button */}
        <PrimaryButton
          name="Login"
          width="30%"
          fontSize="1.3em"
          mobileWidth="50%"
          mobileFontSize="1.3em"
          onClick={loginHandler}
        />
      </div>
      <p style={{ marginTop: 70 }}>Want Login Code ?</p>
      <PrimaryButton
        name="Start my Free Trial"
        width="15%"
        fontSize="1.3em"
        mobileWidth="80%"
        mobileFontSize="1.3em"
        backgroundColor="#228b22"
        onClick={() => setShowCompletePopup(true)}
      />
      <p style={{ marginTop: 50, color: "grey" }}>Contact for Business Enquires: +91 8168278142</p>
      {showCompletePopup && <ContactUsPopup setShowCompletePopup={setShowCompletePopup} />}
    </div>
  );
};

export default Login;
