// const Colors = {
//   black: "#121212",
//   white: "#FDFEFF",
//   grey: "#E5E7EA",
//   disable: "#696969",
//   lightblack: "#444444",
//   lightGrey: "#EBEDEF",
//   veryLightGrey: "#F9FBFC",
//   lightBlue: "#E8f5FE",
//   blue: "#1B98F5",
//   purple: "#662EC7",
//   green: "#32cd32",
// };

const Colors = {
  menuColor: "#662ec7",
  menuHoverColor: "#7d54d6",
  menuShadeColor: "#f3eaff",
  boxColor: "#E8f5FE",
  boxHoverColor: "#1b97f5ac",
  lightTextColor: "#ffffff",
  darkTextColor: "#121212",
  errorColor: "#FF1A1A",
};

export const setCSSColors = () => {
  const root = document.documentElement; // Get the root element

  // Setting default values if not set
  const setDefaultColors = () => {
    const menuColor = getComputedStyle(root).getPropertyValue("--menuColor").trim();
    const menuHoverColor = getComputedStyle(root).getPropertyValue("--menuHoverColor").trim();
    const menuShadeColor = getComputedStyle(root).getPropertyValue("--menuShadeColor").trim();

    // Check if each value is empty and set the default value if it is
    if (!menuColor) {
      root.style.setProperty("--menuColor", Colors.menuColor);
    }

    if (!menuHoverColor) {
      root.style.setProperty("--menuHoverColor", Colors.menuHoverColor);
    }

    if (!menuShadeColor) {
      root.style.setProperty("--menuShadeColor", Colors.menuShadeColor);
    }
  };

  // Call the function to set default colors if necessary
  setDefaultColors();

  root.style.setProperty("--boxColor", Colors.boxColor);
  root.style.setProperty("--boxHoverColor", Colors.boxHoverColor);
  root.style.setProperty("--lightTextColor", Colors.lightTextColor);
  root.style.setProperty("--darkTextColor", Colors.darkTextColor);
};

export default Colors;

export const getDarkShade = (hexColor) => {
  // Remove '#' if present
  hexColor = hexColor.replace("#", "");

  // Convert hex to RGB
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate 50% opacity of the color
  const opacity = 0.3;

  // Calculate darker shade by reducing the lightness
  const darkerR = Math.floor(r * (1 - opacity));
  const darkerG = Math.floor(g * (1 - opacity));
  const darkerB = Math.floor(b * (1 - opacity));

  // Convert RGB to hex
  const darkerHex = `#${((1 << 24) + (darkerR << 16) + (darkerG << 8) + darkerB)
    .toString(16)
    .slice(1)}`;

  return darkerHex;
};

// Input Hex Code and opacity from 0-1
export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  return `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${alpha})`;
};
