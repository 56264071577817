// Different than using  { obj && } because it returns different output for {} / null / undefined
// Using obj && Object.values(obj).length == 0 causes different output when undefined

// Check if object is empty / null / undefined and returns true
export const isEmpty = (input) => {
  // Checking null or undefined
  if (!input) return true;

  // Checking object is empty
  if (typeof input === "object") {
    return Object.keys(input).length === 0;
  }

  // Checking array empty
  if (Array.isArray(input)) {
    return input.length === 0;
  }

  // Checking if input is an empty string
  if (typeof input === "string") {
    return input.trim().length === 0;
  }

  // Otherwise false
  return false;
};
