import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../utils/UserContext";
import { getDatabase, ref, get } from "firebase/database";
import PrimaryButton from "../../components/PrimaryButton";
import { useIsMobileDevice } from "../../hooks/useIsMobileDevice";
import styles from "../../styles/testStyles/PreviousYearExam.module.css";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import Header from "./../../components/Header";

const Filter = ({ value, setValue, options, title }) => (
  <select
    value={value}
    className={styles.selectDropdown}
    onChange={(e) => setValue(e.target.value)}
  >
    <option value="" disabled>
      {title}
    </option>
    {options.map((option) => (
      <option key={option} value={option} style={{ color: "#121212" }}>
        {option}
      </option>
    ))}
  </select>
);

const PreviousYearExam = () => {
  const { user } = useUserContext();
  const [examsData, setExamsData] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [classValue, setClassValue] = useState(user?.selectedClass || "");
  const [boardValue, setBoardValue] = useState("");
  const navigate = useNavigate();
  const isMobileDevice = useIsMobileDevice();

  const classLevel = ["Class 10", "Class 12"];
  const boardType = ["CBSE"];

  // Fetch exams data from Firebase on component mount
  useEffect(() => {
    const fetchExamsData = async () => {
      const db = getDatabase();
      const examsRef = ref(db, "previousYearExams");

      try {
        const snapshot = await get(examsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const examsArray = Object.entries(data);
          setExamsData(examsArray);
          setFilteredExams(examsArray); // Initially set filteredExams to all exams
        } else {
          console.log("No exam data found.");
        }
      } catch (error) {
        console.error("Error fetching exam data from Firebase:", error);
      }
    };

    fetchExamsData();
  }, []);

  // Filter exams based on selected class and board
  useEffect(() => {
    const filtered = examsData.filter(([, exam]) => {
      const classMatch = !classValue || exam.className === classValue;
      const boardMatch = !boardValue || exam.board === boardValue;
      return classMatch && boardMatch;
    });
    setFilteredExams(filtered);
  }, [classValue, boardValue, examsData]);

  const handleViewExam = async (examId) => {
    const storage = getStorage();
    const exam = examsData.find(([id]) => id === examId)?.[1]; // Fetch exam by its ID

    if (!exam || !exam.url) {
      console.error("Exam data is missing or incomplete:", exam);
      alert("Exam data is missing or incomplete.");
      return;
    }

    try {
      const fileRef = storageRef(storage, exam.url);
      const url = await getDownloadURL(fileRef);

      if (url) {
        navigate("/view-previous-exam", { state: { examUrl: url } });
      } else {
        alert("Failed to retrieve exam URL. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching exam URL:", error);
      alert("Failed to load the exam. Please try again.");
    }
  };

  return (
    <div className={styles.container}>
      <Menu />
      {isMobileDevice && (
        <>
          <Header>
            <h2 className={styles.heading}>Previous Year Exams</h2>
          </Header>
        </>
      )}
      <div className={styles.mainPage}>
        <div className={styles.filterContainer}>
          {!isMobileDevice && <p className={styles.filterHeading}>Filter with:</p>}
          <Filter
            value={classValue}
            setValue={setClassValue}
            options={classLevel}
            title="Select Class"
          />
          <Filter
            value={boardValue}
            setValue={setBoardValue}
            options={boardType}
            title="Select Board"
          />
        </div>

        {/* Displaying Exams */}
        {filteredExams.length > 0 ? (
          filteredExams.map(([examId, exam]) => (
            <div className={styles.questionItem} key={examId}>
              <div className={styles.textContainer}>
                <p className={styles.questionText}>{exam.name}</p>
              </div>
              <PrimaryButton
                name="View Exam"
                width="25%"
                mobileWidth="70%"
                onClick={() => handleViewExam(examId)}
              />
            </div>
          ))
        ) : (
          <p>No Exam Found</p>
        )}
      </div>
    </div>
  );
};

export default PreviousYearExam;
