// Functions for Locally saved login code

// Deleting Login Code
export const deleteLoginCodeLocally = () => {
  localStorage.removeItem("loginCode");
};

// Get Login Code
export const getLoginCodeLocally = () => {
  return localStorage.getItem("loginCode");
};

// Set Login Code Locally
export const setLoginCodeLocally = (input) => {
  localStorage.setItem("loginCode", input);
};
