import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { isEmpty } from "../functions/isEmpty";
import { allBrands } from "../components/BrandIdentity";
import {
  generateTest,
  updateOnFirebase,
  useSyncWithFirebase,
  readOnceFromFirebase,
  getPushKeyFromFirebase,
} from "./firebase";

const UserContext = createContext();

const UserContextProvider = ({ loginCode, children }) => {
  // Paths
  const userPath = `users/${loginCode}`;
  const testsDisplayPath = `tests/${loginCode}/display`;
  const testsQuestionsPath = `tests/${loginCode}/questions`;
  const chatsPath = `conversations/${loginCode}/chats`;
  const conversationsDisplayPath = `conversations/${loginCode}/display`;

  // Fetching User
  const [user, setUser] = useState({});
  useSyncWithFirebase({ path: userPath, setFunction: setUser });

  // Selected Subjects of the user
  const subjects = !isEmpty(user?.subjects) ? Object.keys(user?.subjects) : [];

  const [brand, setBrand] = useState(allBrands["studentai"]);

  // Check if loginCode contains any brand key
  useEffect(() => {
    if (loginCode) {
      const brandName = Object.keys(allBrands).find((key) => loginCode.includes(key));

      // If a match is found, set the brand to that value; otherwise, keep it as the default
      if (brandName) {
        setBrand(allBrands[brandName]);

        // Setting Theme of Menu Color
        const root = document.documentElement;
        root.style.setProperty("--menuColor", allBrands?.[brandName]?.menuColor);
        root.style.setProperty("--menuHoverColor", allBrands?.[brandName]?.menuHoverColor);
        root.style.setProperty("--menuShadeColor", allBrands?.[brandName]?.menuShadeColor);
      }
    }
  }, [loginCode]);

  // Created Test by the user
  const [testKey, setTestKey] = useState("");
  const [testQuestions, setTestQuestions] = useState({});
  const generateTestHandler = useCallback(
    async (testDisplay) => {
      try {
        const description = `Generate exactly ${testDisplay?.numberOfQuestions} ${testDisplay?.questionType} questions of subject ${testDisplay?.subject} topic ${testDisplay?.topic} for Class ${user?.selectedClass} student in India of Difficulty ${testDisplay?.difficulty}. Student has scored ${user?.classScore} in previous class and student has target ${user?.futureTarget}`;

        // Getting text answer
        const response = await generateTest({ description });
        console.log("One Test Generated");
        let testQuestions;

        // Checking JSON response and adding to testDisplay
        try {
          testQuestions = JSON.parse(response.data);
        } catch (parseError) {
          console.log("Invalid JSON response, retrying test generation...");
          // Retry test generation if JSON error occured
          // generateTestHandler(testDisplay);
          setTestKey(null);
          return;
        }

        // Check if the generated questions match the expected count
        const questionCount = isEmpty(testQuestions) ? 0 : Object.keys(testQuestions).length;

        if (questionCount !== parseInt(testDisplay?.numberOfQuestions)) {
          console.log(
            `Questions Needed ${testDisplay?.numberOfQuestions} Generated ${questionCount} ${testQuestions}`
          );
          // Retry test generation if the question count doesn't match
          // generateTestHandler(testDisplay);
          setTestKey(null);
          return;
        }

        // Generating new key for test
        const testKey = getPushKeyFromFirebase({ path: testsDisplayPath });

        // Storing test display and questions
        const updates = {};
        updates[`${testsDisplayPath}/${testKey}`] = { ...testDisplay, key: testKey };
        updates[`${testsQuestionsPath}/${testKey}`] = testQuestions;
        updateOnFirebase({ updates });

        setTestKey(testKey);
        setTestQuestions(testQuestions);
        sessionStorage.setItem("localTestKey", testKey);
      } catch (error) {
        console.log("Error Generating Test", error);
      }
    },
    [user, testsQuestionsPath, testsDisplayPath, setTestKey]
  );

  // Handling reload of the Test page
  useEffect(() => {
    const localTestKey = sessionStorage.getItem("localTestKey");

    const fetchLocalTestQuestions = async () => {
      if (localTestKey) {
        setTestKey(localTestKey);
        try {
          const localTestQuestions = await readOnceFromFirebase({
            path: `${testsQuestionsPath}/${localTestKey}`,
          });
          setTestQuestions(localTestQuestions);
        } catch (error) {
          console.error("Error reading local test questions:", error);
        }
      }
    };

    fetchLocalTestQuestions();
  }, [testsQuestionsPath, setTestKey, setTestQuestions]);

  // Reset Test
  const resetTestData = () => {
    // Removing Local Test Variables of previous test
    sessionStorage.removeItem("localTestKey");
    setTestKey("");
    setTestQuestions({});
  };

  const contextValue = {
    user,
    brand,
    subjects,
    loginCode,

    // Paths Used
    userPath,
    testsDisplayPath,
    testsQuestionsPath,
    chatsPath,
    conversationsDisplayPath,

    // Tests
    testKey,
    testQuestions,
    resetTestData,
    generateTestHandler,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used inside UserContextProvider. ");
  }
  return context;
};

export { UserContextProvider, useUserContext };
