import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/ProgressBar.module.css";

const ProgressBarComponent = ({
  ratio,
  percentage,
  duration = 500,
  showText = true,
  progressColor = "#32cd32",
  ...external
}) => {
  const [percentageValue, setPercentageValue] = useState(0);
  const [animatedWidth, setAnimatedWidth] = useState("0%");
  const previousPercentage = useRef(0);

  // Updating Percentage Value
  useEffect(() => {
    if (ratio !== null && ratio !== undefined) {
      setPercentageValue(ratio * 100);
    } else if (percentage !== null && percentage !== undefined) {
      setPercentageValue(percentage);
    }
  }, [ratio, percentage]);

  // Animate the width of the progress bar
  useEffect(() => {
    const startValue = previousPercentage.current;
    const endValue = percentageValue;

    const animationStartTime = Date.now();

    const animateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - animationStartTime;

      // Adjust the progress interpolation for both increasing and decreasing values
      const progress = Math.max(
        Math.min(
          startValue + (endValue - startValue) * (elapsedTime / duration),
          Math.max(startValue, endValue)
        ),
        Math.min(startValue, endValue)
      );

      setAnimatedWidth(`${progress}%`);

      if (elapsedTime < duration) {
        requestAnimationFrame(animateProgress);
      } else {
        setAnimatedWidth(`${endValue}%`);
      }
    };

    requestAnimationFrame(animateProgress);

    // Update previous percentage after animation completes
    previousPercentage.current = endValue;
  }, [percentageValue, duration]);

  return (
    <div className={styles.progressBar} style={{ ...external }}>
      <div
        className={styles.progress}
        style={{
          width: animatedWidth,
          backgroundColor: progressColor,
        }}
      />
      {showText && <span className={styles.percentageText}>{Math.round(percentageValue)} %</span>}
    </div>
  );
};

export default ProgressBarComponent;
