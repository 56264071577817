import React from "react";
// import { hexToRGBA } from "../utils/Colors";
import aiGuruLogo from "../assets/aiGuruLogo.png";
import studentAiLogo from "../assets/studentAILogo.png";
import { useUserContext } from "../utils/UserContext";
import styles from "../styles/BrandIdentity.module.css";
import puranMurtiLogo from "../assets/puranMurtiLogo.png";
import aceInstituteLogo from "../assets/aceInstituteLogo.png";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";

export const allBrands = {
  studentai: {
    name: "Student AI",
    brandLogo: studentAiLogo,
    organization: "Livorn",
    menuColor: "#662ec7",
    menuHoverColor: "#7d54d6",
    menuShadeColor: "#f3eaff",
    brandOrganizationLogo: studentAiLogo,
  },
  aiguru: {
    name: "AI Guru",
    brandLogo: aiGuruLogo,
    menuShadeColor: "#e0f7e0",
    organization: "ACE Institute",
    menuColor: "#662ec7",
    menuHoverColor: "#7d54d6",
    brandOrganizationLogo: aceInstituteLogo,
    // menuColor: hexToRGBA("#228b22", 0.9),
    // menuHoverColor: hexToRGBA("#228b22", 0.75),
  },
  puranmurti: {
    name: "Puran Murti",
    brandLogo: puranMurtiLogo,
    menuShadeColor: "#e0f7e0",
    organization: "Livorn",
    menuColor: "#662ec7",
    menuHoverColor: "#7d54d6",
    brandOrganizationLogo: puranMurtiLogo,
    // menuColor: hexToRGBA("#228b22", 0.9),
    // menuHoverColor: hexToRGBA("#228b22", 0.75),
  },
};

const BrandIdentity = ({
  height,
  width,
  fontSize,
  paddingTop,
  marginRight,
  marginBottom,
  paddingLeft,
  color,
}) => {
  const { brand } = useUserContext();
  const isMobileDevice = useIsMobileDevice();

  return (
    <div
      className={styles.container}
      style={{
        paddingTop,
        paddingLeft,
        marginBottom,
      }}
    >
      <img
        src={brand?.brandLogo}
        alt="Brand Logo"
        className={styles.logo}
        style={{
          width,
          height,
          marginRight,
        }}
      />
      <div>
        <h2 className={styles.brandText} style={{ fontSize, color }}>
          {brand?.name}
        </h2>
        {!isMobileDevice && (
          <p className={styles.brandOrganization}>Product of {brand?.organization}</p>
        )}
      </div>
    </div>
  );
};

export default BrandIdentity;
