import React from "react";
import Menu from "../components/Menu";
import styles from "../styles/Help.module.css";
import PrimaryButton from "../components/PrimaryButton";
import Header from "../components/Header";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";

const HelpPage = () => {
  const isMobileDevice = useIsMobileDevice();
  const whatsappUrl = "https://wa.me/918168278142?text=I%20have%20an%20issue%20with%20software";

  return (
    <div className={styles.container}>
      {isMobileDevice && (
        <>
          <Header>
            <h2 className={styles.heading}>Help</h2>
          </Header>
        </>
      )}
      <div className={styles.mainHavingIssue}>
        <div className={styles.boxContainer}>
          <p className={styles.text}>
            If you're experiencing any issues with the software, having trouble using it, or facing
            any other challenges, feel free to message us on WhatsApp.
          </p>
          <PrimaryButton
            name="Send Message"
            fontSize="1.5em"
            marginTop={50}
            backgroundColor="#55d555"
            onClick={() => window.open(whatsappUrl, "_blank")}
          />
        </div>
      </div>
    </div>
  );
};

const Help = () => {
  return (
    <div className={styles.container}>
      <Menu />
      <HelpPage />
    </div>
  );
};

export default Help;
