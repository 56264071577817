import React, { useState } from "react";
import Menu from "../../components/Menu";
import { useNavigate } from "react-router-dom";
// import { isDevelopmentMode } from "../../utils/Constants";
import { toTitleCase } from "../../functions/toTitleCase";
import { useUserContext } from "../../utils/UserContext";
import useEnterPressed from "../../hooks/useEnterPressed";
import PrimaryButton from "../../components/PrimaryButton";
import { cleanUpInput } from "../../functions/cleanUpInput";
import LabelComponent from "../../components/LabelComponent";
import ProgressBarComponent from "../../components/ProgressBarComponent";
import styles from "../../styles/testStyles/CreateTest.module.css";
import { useIsMobileDevice } from "../../hooks/useIsMobileDevice";
import Header from "../../components/Header";
import DoubtHistoryIcon from "../../assets/DoubtHistoryIcon";
import { giveHapticFeedback } from "../../functions/giveHapticFeedback";

const CreateTest = () => {
  const navigate = useNavigate();
  const [topic, setTopic] = useState("");
  const [step, setStep] = useState(0);
  const isMobileDevice = useIsMobileDevice();
  const [subject, setSubject] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState("");
  const { subjects, resetTestData, generateTestHandler } = useUserContext();

  // Next Button
  const handleNext = () => {
    if (
      (step === 0 && topic) ||
      (step === 1 && subject) ||
      (step === 2 && difficulty) ||
      (step === 3 && numberOfQuestions) ||
      (step === 4 && questionType)
    ) {
      setStep((prevStep) => prevStep + 1);
    }
    if (step === 5) {
      handleStartTest();
    }
  };

  // Back Button
  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // Storing User Data
  const handleStartTest = async () => {
    const testDisplay = {
      subject,
      difficulty,
      numberOfQuestions,
      questionType,
      creationDate: new Date().toString(),
      topic: cleanUpInput(toTitleCase(topic)),
    };

    giveHapticFeedback();

    // Resetting Test Data
    resetTestData();

    // Creating Test
    generateTestHandler(testDisplay);

    // Navigating to Test Screen
    navigate("/test-screen", { state: { testDisplay } });
  };

  // Only Enable in Development, Speeding Up process in Development Mode
  // useEffect(() => {
  //   console.log("This is development mode, Setting Defaults", isDevelopmentMode);
  //   if (isDevelopmentMode) {
  //     setTopic("Algebra");
  //     setSubject("Mathematics");
  //     setDifficulty("Medium");
  //     setNumberOfQuestions("5");
  //     setQuestionType("MCQ");
  //     setStep(5);
  //   }
  // }, []);

  // Going to next if enter pressed
  useEnterPressed(handleNext);

  return (
    <div className={styles.container}>
      {!isMobileDevice && <Menu />}
      {isMobileDevice && (
        <>
          <Header>
            <div className={styles.history} onClick={() => navigate("/test-history")}>
              <DoubtHistoryIcon stroke="#444444" height="18" width="18" />
            </div>
          </Header>
        </>
      )}
      <div className={styles.mockTestPaper}>
        <h1 className={styles.heading}>
          {step === 0 && "Create a test and make a weak topic strong"}
          {step >= 1 && step <= 4 && `Creating test on "${topic}"`}
          {step === 5 && "Click 'Start Test' to begin"}
        </h1>
        <ProgressBarComponent ratio={step / 5} />
        <div className={styles.formContainer}>
          {step === 0 && (
            <div className={styles.testCreationForm}>
              <h2 className={styles.subHeading}>Which topic would you like to strengthen?</h2>
              <input
                type="text"
                placeholder="Enter topic"
                value={topic}
                className={styles.topicInput}
                onChange={(e) => setTopic(e.target.value)}
              />
              <PrimaryButton
                name="Start Creating"
                onClick={handleNext}
                mobileFontSize="1.2em"
                disabled={!topic} // Disable if no topic is entered
              />
            </div>
          )}

          {step > 0 && (
            <div className={styles.testStepContent}>
              <div className={styles.selectedOptions}>
                <LabelComponent title="Subject - " label={subject} />
                <LabelComponent marginTop={10} title="Difficulty Level - " label={difficulty} />
                <LabelComponent
                  marginTop={10}
                  label={numberOfQuestions}
                  title="No. of Questions - "
                />
                <LabelComponent marginTop={10} title="Question Type - " label={questionType} />
              </div>

              {/* TODO => Remove Steps Code Here and make a single component in the same file */}
              <div className={styles.dropdownContainer}>
                {step === 1 && (
                  <select
                    value={subject}
                    className={styles.selectDropdown}
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Subject
                    </option>
                    {subjects.map((subject) => (
                      <option key={subject} value={subject}>
                        {subject}
                      </option>
                    ))}
                  </select>
                )}

                {step === 2 && (
                  <select
                    value={difficulty}
                    className={styles.selectDropdown}
                    onChange={(e) => setDifficulty(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Difficulty Level
                    </option>
                    <option value="Easy">Easy</option>
                    <option value="Medium">Medium</option>
                    <option value="Hard">Hard</option>
                  </select>
                )}
                {step === 3 && (
                  <select
                    value={numberOfQuestions}
                    className={styles.selectDropdown}
                    onChange={(e) => setNumberOfQuestions(e.target.value)}
                  >
                    <option value="" disabled>
                      Select No. of Questions
                    </option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </select>
                )}
                {step === 4 && (
                  <select
                    value={questionType}
                    className={styles.selectDropdown}
                    onChange={(e) => setQuestionType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Question Type
                    </option>
                    <option value="MCQ">MCQ</option>
                    <option value="True / False">True / False</option>
                  </select>
                )}
              </div>

              <div className={styles.navigationButtons}>
                {step > 0 && (
                  <PrimaryButton
                    name="Back"
                    backgroundColor="#6c757d"
                    onClick={handleBack}
                    mobileFontSize="1.2em"
                    mobileWidth="40%"
                  />
                )}
                {step < 5 && (
                  <PrimaryButton
                    name="Next"
                    onClick={handleNext}
                    mobileFontSize="1.2em"
                    mobileWidth="40%"
                    disabled={
                      (step === 1 && !subject) ||
                      (step === 2 && !difficulty) ||
                      (step === 3 && !numberOfQuestions) ||
                      (step === 4 && !questionType)
                    } // Disable if any selection is missing
                  />
                )}
                {step === 5 && (
                  <PrimaryButton
                    name="Start Test"
                    backgroundColor="#32cd32"
                    onClick={handleStartTest}
                    mobileFontSize="1.1em"
                    mobileWidth="55%"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateTest;
