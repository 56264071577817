import MenuItem from "./MenuItem";
import BrandIdentity from "./BrandIdentity";
import styles from "../styles/Menu.module.css";
import SettingsIcon from "../assets/SettingsIcon";
import CreateTestIcon from "../assets/CreateTestIcon";
import React, { useState, useEffect } from "react";
import HelpIcon from "../assets/Help";
import DoubtIcon from "../assets/DoubtIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";
import DoubtHistoryIcon from "../assets/DoubtHistoryIcon";
import ImportantQuestions from "../assets/ImportantQuestionsIcon";
import FeedbackIcon from "../assets/FeedbackIcon";

export const menuItems = [
  {
    name: "Ask Doubts",
    path: "/ask-doubt",
    icon: DoubtIcon,
    section: "Doubts",
  },
  {
    name: "Doubts History",
    path: "/doubts-history",
    icon: DoubtHistoryIcon,
    section: "Doubts",
  },
  {
    name: "Create Test",
    path: "/test",
    icon: CreateTestIcon,
    section: "Tests",
  },
  {
    name: "Tests History",
    path: "/test-history",
    icon: DoubtHistoryIcon,
    section: "Tests",
  },
  {
    name: "Previous Year Exams",
    path: "/previous-year-exams",
    icon: ImportantQuestions,
    section: "Tests",
  },
  {
    name: "Help & Support",
    path: "/help",
    icon: HelpIcon,
    section: "General",
  },
  {
    name: "Give Feedback",
    path: "/feedback",
    icon: FeedbackIcon,
    section: "General",
  },
  {
    name: "Settings",
    path: "/settings",
    icon: SettingsIcon,
    section: "General",
  },
  // {
  //   name: "Download Mobile App",
  //   path: "/settings",
  //   icon: SettingsIcon,
  //   section: "General",
  // },
];

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileDevice = useIsMobileDevice();
  const sections = ["Doubts", "Tests", "General"];
  const [selectedItem, setSelectedItem] = useState("Ask Question");

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = menuItems.find((item) => item.path === currentPath);
    setSelectedItem(currentItem ? currentItem.name : "Ask Question");
  }, [location.pathname]);

  const handleMenuClick = (item) => {
    setSelectedItem(item.name);
    navigate(item.path);
  };

  if (!isMobileDevice) {
    return (
      <div className={styles.menu}>
        <div className={styles.menuSection}>
          <BrandIdentity marginBottom={20} />

          {sections.map((section) => (
            <div key={section}>
              <p className={styles.quick}>{section}</p>
              {menuItems
                .filter((item) => item.section === section)
                .map((item) => (
                  <MenuItem
                    key={item.name}
                    name={item.name}
                    IconComponent={item.icon}
                    selectedItem={selectedItem}
                    onClick={() => handleMenuClick(item)}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default Menu;
