import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Colors from "../../utils/Colors";
import DoubtsMessage from "./DoubtsMessage";
import { isEmpty } from "../../functions/isEmpty";
import { set, ref, push } from "firebase/database";
import { useUserContext } from "../../utils/UserContext";
import PrimaryButton from "../../components/PrimaryButton";
import { cleanUpInput } from "../../functions/cleanUpInput";
import messageSound from "../../assets/newMessageSound.mp3";
// import { MathJax, MathJaxContext } from "better-react-mathjax";
import LottieAnimation from "../../components/LottieAnimation";
import { useIsMobileDevice } from "../../hooks/useIsMobileDevice";
import styles from "../../styles/doubtsStyles/DoubtsChat.module.css";
import {
  db,
  getTextAnswer,
  getChatTitle,
  useSyncWithFirebase,
  getImageAnswer,
} from "../../utils/firebase";

const questionSuggestions = ["Explain in Hindi", "Explain More", "Explain in Hinglish"];

const DoubtsChat = ({ subject, chatKey, initialQuestion, setChatKey }) => {
  const chatEndRef = useRef(null);
  const latestImageRef = useRef(null);
  const isMobileDevice = useIsMobileDevice();
  const { user, brand, chatsPath, conversationsDisplayPath } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [chat, setChat] = useState({});
  const isChatEmpty = isEmpty(chat);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const chatsArray = useMemo(() => (!isEmpty(chat) ? Object.values(chat) : []), [chat]);
  const audioRef = useRef(new Audio(messageSound));

  const formatAnswer = (input) => {
    let string = input.replace(/\*\*/g, "");
    return string.replace(/(?<!\n)\n(?!\n)/g, "\n\n");
  };

  const generateChatTitle = async (input) => {
    try {
      const response = await getChatTitle({ question: input });
      return JSON.parse(response.data)?.title || "Your Question";
    } catch (error) {
      console.error("Error getting title for text:", error);
      return "Your Question";
    }
  };

  // Scrolling to Bottom
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat]);

  useEffect(() => {
    if (!chatKey) {
      setChat({});
    }
  }, [chatKey]);

  // Adding message to chat
  const addToChat = useCallback(
    async (role, content, inputChatKey) => {
      let finalChatKey = inputChatKey || chatKey;

      if (!finalChatKey) {
        finalChatKey = push(ref(db, chatsPath)).key;
        const title = await generateChatTitle(content);
        const conversationDisplay = {
          title,
          subject,
          key: finalChatKey,
          isImportant: false,
          time: new Date().getTime(),
        };

        sessionStorage.setItem(subject, finalChatKey);
        set(ref(db, `${conversationsDisplayPath}/${finalChatKey}`), conversationDisplay);
      }

      const messageKey = push(ref(db, `${chatsPath}/${finalChatKey}`)).key;
      set(ref(db, `${chatsPath}/${finalChatKey}/${messageKey}`), { role, content });
      setChatKey(finalChatKey);

      // Play sound if the role is 'assistant'
      if (role === "assistant") {
        audioRef.current.play().catch((error) => console.error("Audio playback failed:", error));
      }

      // Get Answer if User has asked the question
      if (role === "user") {
        if (!content.startsWith("<img")) {
          const backendInputs = {
            description: `Give answer according to ${subject} of class ${user?.selectedClass}`,
            conversation: [...chatsArray, { role, content }],
          };
          setIsLoading(true);
          try {
            const response = await getTextAnswer(backendInputs);
            const answer = response.data;

            addToChat("assistant", formatAnswer(answer), finalChatKey);
          } catch (error) {
            console.error("Error getting answer:", error);
          }
        } else if (content.startsWith("<img")) {
          setIsLoading(true);
          try {
            const response = await getImageAnswer({ image: latestImageRef.current });
            console.log(response);
            const answer = response.data;

            addToChat("assistant", formatAnswer(answer), finalChatKey);
          } catch (error) {
            console.error("Error getting answer:", error);
          }
        }
      }
    },
    [user, chatKey, chatsPath, conversationsDisplayPath, chatsArray, subject, setChatKey]
  );

  // Syncing chat
  useSyncWithFirebase({
    setFunction: setChat,
    setIsLoading: setIsLoading,
    path: chatKey ? `${chatsPath}/${chatKey}` : null,
  });

  // Sending Question
  const sendQuestionHandler = useCallback(
    async (question) => {
      setIsLoading(true);
      addToChat("user", cleanUpInput(question));
    },
    [setIsLoading, addToChat]
  );

  // Asking initialQuestion from View Test Screen
  const hasInitialQuestionHandled = useRef(false);
  useEffect(() => {
    if (initialQuestion && !hasInitialQuestionHandled.current) {
      sendQuestionHandler("Explain this Step by Step" + JSON.stringify(initialQuestion));
      hasInitialQuestionHandled.current = true;
    }
  }, [initialQuestion, sendQuestionHandler]);

  // Define the onSendImage function
  const onSendImage = (imageUrl) => {
    latestImageRef.current = imageUrl;

    addToChat(
      "user",
      `<img src="${imageUrl}" alt="User uploaded image" class="${styles.chatImage}" />`
    );
  };

  // Checking for
  const isLastEntryAssistant = () => {
    const keys = Object.keys(chat);
    if (keys.length === 0) return false; // Handle empty object case

    // Sort keys and get the last key
    const lastKey = keys.sort().pop();

    // Check if the role of the last entry is 'assistant'
    return chat[lastKey]?.role === "assistant";
  };

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chats}>
        {/* Rendering Chat */}
        {!isChatEmpty &&
          Object.entries(chat).map(([key, msg]) => (
            <div key={key} className={msg.role === "user" ? styles.rightChat : styles.leftChat}>
              <div dangerouslySetInnerHTML={{ __html: msg.content.replace(/\n/g, "<br />") }} />
            </div>
          ))}

        {/* Question Suggestions */}
        {isLastEntryAssistant() && (
          <div className={styles.questionSuggestions}>
            {questionSuggestions.map((value) => (
              <PrimaryButton
                key={value}
                width="18%"
                mobileWidth="100%"
                name={value}
                color="#121212"
                marginRight={10}
                border="1px solid #1b98f5"
                marginBottom={10}
                backgroundColor={Colors.boxColor}
                hoverColor={Colors.boxHoverColor}
                onClick={() => sendQuestionHandler(value)}
              />
            ))}
          </div>
        )}

        {/* Empty Animation */}
        {isChatEmpty && !isLoading && !isImageUploading && (
          <div className={styles.animationContainer}>
            {brand?.brandOrganizationLogo && (
              <img
                alt="Brand Logo"
                src={brand?.brandOrganizationLogo}
                className={styles.brandOrganizationLogo}
              />
            )}

            <h4 className={styles.clickToAsk}>Click to Ask "{subject}" Doubts & Concepts</h4>
            <LottieAnimation
              name="arrow"
              animationStyle={{
                marginTop: 20,
                alignSelf: "center",
                width: isMobileDevice ? 120 : 150,
                height: isMobileDevice ? 120 : 150,
                marginLeft: isMobileDevice ? -50 : -100,
              }}
            />
          </div>
        )}

        {/* Books Loading Animation */}
        {isLoading && (
          <LottieAnimation
            name="books"
            animationStyle={{ width: 600, height: 600, alignSelf: "center" }}
          />
        )}

        {/* Image Uploading Animation */}
        {isImageUploading && (
          <div className={styles.cropContainer}>
            <LottieAnimation
              name="uploading"
              speed={0.4}
              animationStyle={{ width: 380, height: 450, alignSelf: "center" }}
            />
            <h4 style={{ color: "White" }}>Analyzing Image...</h4>
          </div>
        )}

        {/* Move Animation to Doubts Chat */}
        {/* {isImageUploading && (
        <div className={styles.uploadingPopup}>
          <LottieAnimation
            name="uploading"
            speed={0.4}
            animationStyle={{ width: 300, height: 300, alignSelf: "center" }}
          />
          <h4>Analyzing Image...</h4>
        </div>
      )} */}

        <div ref={chatEndRef} />
      </div>

      {/* Bottom Chat Section */}
      <DoubtsMessage
        onSendImage={onSendImage}
        onSendMessage={sendQuestionHandler}
        isImageUploading={isImageUploading}
        setIsImageUploading={setIsImageUploading}
      />
    </div>
  );
};

export default DoubtsChat;
