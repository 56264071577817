import React from "react";

const DoubtIcon = ({ stroke, width = "22", height = "22" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5 47C12.0735 47 2 36.9265 2 24.5C2 12.0736 12.0735 2 24.5 2C36.9264 2 47 12.0736 47 24.5C47 27.4927 46.4156 30.3492 45.3548 32.9612C45.1518 33.4612 45.0503 33.7112 45.005 33.9132C44.9607 34.1107 44.9445 34.257 44.9445 34.4598C44.9444 34.6668 44.982 34.8923 45.0572 35.3435L46.5397 44.238C46.6949 45.1695 46.7725 45.6353 46.6281 45.972C46.5017 46.2668 46.2668 46.5017 45.9721 46.628C45.6353 46.7725 45.1695 46.695 44.2381 46.5398L35.3434 45.0573C34.8923 44.982 34.6667 44.9445 34.4597 44.9445C34.2571 44.9445 34.1109 44.9607 33.9132 45.005C33.7112 45.0502 33.4612 45.1517 32.9613 45.3547C30.3493 46.4157 27.4927 47 24.5 47Z"
      stroke={stroke}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DoubtIcon;
