import React, { useState } from "react";
import Menu from "../components/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../styles/QuestionDisplay.module.css";
// import ChatAskQuestion from "./doubts/DoubtsChat";
import PrimaryButton from "../components/PrimaryButton";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";
import DoubtsChat from "./doubts/DoubtsChat";

const QuestionDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobileDevice = useIsMobileDevice();
  const { conversation } = location.state || {};
  const [chatKey, setChatKey] = useState(conversation?.key);

  return (
    <div className={styles.container}>
      {!isMobileDevice && <Menu />}
      <div className={styles.mainContent}>
        {!isMobileDevice && (
          <div className={styles.header}>
            <PrimaryButton
              name="Back"
              backgroundColor="#444444"
              onClick={() => navigate(-1)}
              className={styles.backButton}
            />
            <p className={styles.heading}>{conversation?.title}</p>
          </div>
        )}

        {isMobileDevice && (
          <>
            <PrimaryButton
              name="Back"
              backgroundColor="#444444"
              onClick={() => navigate(-1)}
              marginTop={10}
              mobileWidth="40%"
            />
            <p className={styles.heading}>{conversation?.title}</p>
          </>
        )}

        <DoubtsChat chatKey={chatKey} setChatKey={setChatKey} subject={conversation?.subject} />
      </div>
    </div>
  );
};

export default QuestionDisplay;
