import React from "react";
import styles from "../styles/IconButton.module.css";

// TODO => Later extract <Logo /> from here and write styles there
const IconButton = ({ Logo, name, onClick, fill }) => {
  return (
    <div className={styles.iconButton} onClick={onClick}>
      {/* Display logo/icon */}
      <Logo className={styles.icon} fill={fill} />
      {/* Display name (text) if it exists, else just show the icon */}
      {name && <span className={styles.name}>{name}</span>}
    </div>
  );
};

export default IconButton;
