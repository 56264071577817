import { useEffect } from "react";

const useEnterPressed = (callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        callback();
      }
    };

    // Add event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [callback]); // Only re-run if the callback changes
};

export default useEnterPressed;
