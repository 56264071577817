import React, { useState } from "react";
import styles from "../styles/MenuItem.module.css";

const MenuItem = ({ IconComponent, name, selectedItem, onClick }) => {
  const isSelected = selectedItem === name;
  const [isHovered, setIsHovered] = useState(false);

  // Determine stroke color based on state
  const strokeColor = isSelected || isHovered ? "#fff" : "#121212";

  return (
    <div
      className={isSelected ? styles.selectedMenuItem : styles.menuItem}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconComponent stroke={strokeColor} className={styles.icon} />
      <span className={isSelected ? styles.selectedMenuText : styles.menuText}>{name}</span>
    </div>
  );
};

export default MenuItem;
