import React, { useRef, useEffect, useState } from "react";
import { useUserContext } from "../../utils/UserContext";
import styles from "../../styles/doubtsStyles/SubjectSelector.module.css";

const SubjectSelector = ({ selectedSubject, setSelectedSubject }) => {
  const { subjects } = useUserContext();
  const scrollContainerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubjectClick = (subject) => {
    setSelectedSubject(selectedSubject === subject ? null : subject);
  };

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -100, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 100, behavior: "smooth" });
  };

  if (isMobile) {
    return (
      <div className={styles.subjectSelectorWrapper}>
        <select
          className={styles.subjectDropdown}
          value={selectedSubject || ""}
          onChange={(e) => handleSubjectClick(e.target.value)}
        >
          <option value="" disabled>
            Select a subject
          </option>
          {subjects.map((subject) => (
            <option key={subject} value={subject}>
              {subject}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className={styles.subjectSelectorWrapper}>
      <button className={styles.arrowButton} onClick={scrollLeft}>
        &lt;
      </button>

      <div className={styles.subjectSelectorContainer} ref={scrollContainerRef}>
        {subjects.map((subject) => (
          <span
            key={subject}
            className={`${styles.subject} ${
              selectedSubject === subject ? styles.selectedSubject : ""
            }`}
            onClick={() => handleSubjectClick(subject)}
          >
            {subject}
          </span>
        ))}
      </div>

      <button className={styles.arrowButton} onClick={scrollRight}>
        &gt;
      </button>
    </div>
  );
};

export default SubjectSelector;
