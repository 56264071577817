import React from "react";

const EditIcon = ({ stroke = "#444444" }) => (
  <svg width="19" height="19" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6959 13.1583L16.7636 8.05593M2.58331 27.8276L6.75648 27.348C7.26634 27.2893 7.52127 27.2601 7.75956 27.1802C7.97095 27.1094 8.17214 27.0094 8.35764 26.8829C8.56672 26.7403 8.74811 26.5526 9.11085 26.1773L25.3951 9.33152C26.7572 7.92254 26.7572 5.63812 25.3951 4.22914C24.0332 2.82017 21.8249 2.82016 20.4628 4.22914L4.17857 21.075C3.81583 21.4503 3.63446 21.6379 3.49657 21.8541C3.37424 22.0461 3.27754 22.2541 3.20911 22.4729C3.13198 22.7193 3.10364 22.9831 3.047 23.5106L2.58331 27.8276Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="22.0833" cy="7.65517" rx="4.33333" ry="4.48276" fill="#444444" />
  </svg>
);

export default EditIcon;
