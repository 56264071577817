import React from "react";
import styles from "../styles/Header.module.css";
import { useNavigate } from "react-router-dom";
import { giveHapticFeedback } from "../functions/giveHapticFeedback";
import backIcon from "../assets/backIcon.svg";

const Header = ({ children, screen = -1 }) => {
  const navigate = useNavigate();

  // Back button handler
  const backHandler = () => {
    giveHapticFeedback();

    if (screen === -1) {
      navigate(-1);
    } else {
      navigate(screen);
    }
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.back} onClick={backHandler}>
        <img src={backIcon} alt="Back" />
      </div>
      <div className={styles.headerContent}>{children}</div>
    </header>
  );
};

export default Header;

// export default Header;
// import MenuDrawer from "./MenuDrawer";
// import React, { useState } from "react";
// import BrandIdentity from "./BrandIdentity";
// import styles from "../styles/Header.module.css";
// import { giveHapticFeedback } from "../functions/giveHapticFeedback";

// const Header = () => {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   const toggleDrawer = () => {
//     giveHapticFeedback();
//     setIsDrawerOpen(!isDrawerOpen);
//   };

//   return (
//     <header className={`${styles.headerContainer} ${isDrawerOpen ? styles.drawerOpen : ""}`}>
//       <div className={styles.header}>
//         <button
//           className={`${styles.menuButton} ${isDrawerOpen ? styles.menuButtonActive : ""}`}
//           onClick={toggleDrawer}
//         >
//           {isDrawerOpen ? "✕" : "☰"}
//         </button>
//         <div className={styles.brand}>
//           <BrandIdentity
//             marginBottom={0}
//             paddingTop={0}
//             paddingLeft={0}
//             height={38}
//             width={38}
//             marginRight={10}
//             fontSize="1.4em"
//           />
//         </div>
//       </div>
//       {/* MenuDrawer will be shown or hidden based on isDrawerOpen */}
//       <MenuDrawer isOpen={isDrawerOpen} closeDrawer={toggleDrawer} />
//     </header>
//   );
// };

// export default Header;
