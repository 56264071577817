import React, { useState } from "react";
import { Test, TestReportPopup } from "./TestScreen";
import { useUserContext } from "../../utils/UserContext";
import { useSyncWithFirebase } from "../../utils/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/testStyles/ViewTest.module.css";
import PrimaryButton from "../../components/PrimaryButton";

const ViewTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { testDisplay, testKey } = location.state || {};
  const [showReportPopup, setShowReportPopup] = useState(false);

  // Syncing Test Questions
  const { testsQuestionsPath } = useUserContext();
  const [testQuestions, setTestQuestions] = useState({});
  useSyncWithFirebase({ path: `${testsQuestionsPath}/${testKey}`, setFunction: setTestQuestions });

  const handleBack = () => {
    navigate(-1);
  };

  const handleSeeTestReport = () => {
    setShowReportPopup(true);
  };

  const handleCloseReport = () => {
    setShowReportPopup(false);
    navigate("/test-history");
  };

  return (
    <div className={styles.container}>
      <div className={styles.testInfo}>
        <div className={styles.navigationButtons}>
          <PrimaryButton name="Back" onClick={handleBack} backgroundColor="#696969" marginTop={5} />
          <PrimaryButton
            name="See Test Report"
            onClick={handleSeeTestReport}
            backgroundColor="#32cd32"
            marginTop={5}
          />
        </div>
      </div>

      <Test
        canEdit={false}
        testKey={testKey}
        testQuestions={testQuestions}
        testSubject={testDisplay?.subject}
      />
      {showReportPopup && (
        <TestReportPopup
          onClose={handleCloseReport}
          correctAnswers={testDisplay?.correctAnswers}
          totalQuestions={testDisplay?.numberOfQuestions}
        />
      )}
    </div>
  );
};

export default ViewTest;
