import React from "react";

const DoubtHistoryIcon = ({ stroke = "444444", height = "21", width = "21" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 2C33.0458 2 42 10.9543 42 22C42 33.0456 33.0458 42 22 42C10.9543 42 2 33.0456 2 22C2 15.7172 4.89704 10.111 9.42816 6.44444"
      stroke={stroke}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 5.33337H10.8889V14.2223"
      stroke={stroke}
      strokeWidth="2.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DoubtHistoryIcon;
