import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../functions/isEmpty";
import { useUserContext } from "../../utils/UserContext";
import PrimaryButton from "../../components/PrimaryButton";
import LottieAnimation from "../../components/LottieAnimation";
import styles from "../../styles/testStyles/TestScreen.module.css";
import { sendEmail, updateOnFirebase, readOnceFromFirebase } from "../../utils/firebase";
import { giveHapticFeedback } from "../../functions/giveHapticFeedback";

export const Question = ({ testKey, question, questionKey, testSubject, canEdit }) => {
  const navigate = useNavigate();
  const { testsQuestionsPath } = useUserContext();
  const [selectedOption, setSelectedOption] = useState(null);

  // Updating answers selection to firebase
  const handleAnswerSelect = (optionKey) => {
    const updates = {};
    const newValue = selectedOption === optionKey ? null : optionKey;
    updates[`${testsQuestionsPath}/${testKey}/${questionKey}/userOption`] = newValue;
    updateOnFirebase({ updates });
    setSelectedOption(newValue);
  };

  const doubtHandler = () => {
    navigate("/ask-doubt", { state: { question, testSubject } });
  };

  return (
    <div className={styles.question}>
      {/* Question */}
      <h4 className={styles.questionHeading}>{`${questionKey}: ${question.title}`}</h4>

      {/* Rendering Options */}
      <div className={styles.options}>
        {!isEmpty(question?.options) &&
          Object.entries(question?.options).map(([optionKey, option]) => (
            <label key={optionKey} className={styles.option}>
              {canEdit && (
                <input
                  value={optionKey}
                  type="checkbox"
                  name={questionKey}
                  checked={selectedOption === optionKey}
                  onChange={() => handleAnswerSelect(optionKey)}
                  className={styles.checkBox}
                />
              )}
              {!canEdit && <span>{optionKey}: </span>}
              {option}
            </label>
          ))}
      </div>
      <p className={styles.hint}>Hint - {question?.hint}</p>

      {/* Explanation and User Answer */}
      {!canEdit && (
        <div className={styles.explanationBox}>
          <p className={styles.explanation}>Your Answer - {question?.userOption}</p>
          <p className={styles.explanation}>Correct Answer - {question?.correctOption}</p>
          <p className={styles.explanation}>Explanation - {question?.explanation}</p>
          <PrimaryButton name="Explain More" fontSize="1em" onClick={doubtHandler} />
        </div>
      )}
    </div>
  );
};

export const Test = ({ canEdit = true, testKey, isLoading, testSubject, testQuestions }) => {
  return (
    <div className={styles.test}>
      {/* Questions Mapping */}
      {!isLoading &&
        Object.entries(testQuestions).map(([questionKey, question]) => (
          <Question
            canEdit={canEdit}
            key={questionKey}
            testKey={testKey}
            question={question}
            testSubject={testSubject}
            questionKey={questionKey}
          />
        ))}

      {/* Loading Animation */}
      {isLoading && (
        <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <LottieAnimation name="mocktest" animationStyle={{ height: 400, width: 400 }} />
          <h3>Creating Test ...</h3>
        </div>
      )}
    </div>
  );
};

export const TestReportPopup = ({ totalQuestions, correctAnswers, onClose }) => {
  // TODO => Later also mention Unanswered Questions

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <h3 style={{ marginTop: -5 }} className={styles.heading}>
          Test Report
        </h3>
        <h4 className={styles.result}>Total Questions: {totalQuestions}</h4>
        <h4 className={styles.result}>Correct Answers: {correctAnswers}</h4>
        <h4 className={styles.result}>Wrong Answers: {totalQuestions - correctAnswers}</h4>
        <PrimaryButton
          name="Ok"
          onClick={onClose} // Pass onClose here
          marginTop={20}
          width="40%"
          mobileWidth="60%"
          backgroundColor="#1b98f5"
          mobileFontSize="1em"
        />
      </div>
    </div>
  );
};

export const ReportToParents = ({ onClick }) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <h3 style={{ marginTop: -5 }} className={styles.heading}>
          Complete Test Report Sent To Parent's Email
        </h3>
        <PrimaryButton
          name="Ok"
          onClick={onClick} // Use onClick here instead of onClose
          marginTop={20}
          width="40%"
          mobileWidth="60%"
          backgroundColor="#1b98f5"
          mobileFontSize="1em"
        />
      </div>
    </div>
  );
};

export const Popup = ({ message, onConfirm, onCancel }) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <h4 className={styles.heading}>{message}</h4>
        <div className={styles.navigationButtons}>
          <PrimaryButton
            name="No"
            onClick={onCancel}
            marginTop={20}
            width="40%"
            mobileWidth="50%"
            backgroundColor="#444444"
            mobileFontSize="1em"
          />
          <PrimaryButton
            name="Yes"
            onClick={onConfirm}
            marginTop={20}
            width="40%"
            mobileWidth="50%"
            backgroundColor="#444444"
            mobileFontSize="1em"
          />
        </div>
      </div>
    </div>
  );
};

const TestScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { testDisplay } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const { user, testKey, testQuestions } = useUserContext();
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [showCompletePopup, setShowCompletePopup] = useState(false);
  const [showReportToParent, setshowReportToParent] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0); // State to track elapsed time
  const { testsDisplayPath, testsQuestionsPath, resetTestData } = useUserContext();
  const numberOfQuestions = testDisplay?.numberOfQuestions;

  // Generating Test first time
  useEffect(() => {
    if (isEmpty(testQuestions)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [testQuestions]);

  // Stopwatch effect
  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1); // Increment elapsed time every second
    }, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(timer);
  }, []);

  // Function to format elapsed time
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
      secs
    ).padStart(2, "0")}`;
  };

  const handleCompleteTest = async () => {
    if (testKey) {
      setShowCompletePopup(true);
      giveHapticFeedback();

      // Calculate correctAnswers to testDisplay on firebase
      const finalTestQuestions = await readOnceFromFirebase({
        path: `${testsQuestionsPath}/${testKey}`,
      });

      let correctCount = 0;
      !isEmpty(finalTestQuestions) &&
        Object.values(finalTestQuestions).forEach((question) => {
          if (question.correctOption === question.userOption) {
            correctCount++;
          }
        });

      const updates = { [`${testsDisplayPath}/${testKey}/correctAnswers`]: correctCount };
      updateOnFirebase({ updates });
      setCorrectAnswers(correctCount);
    }
  };

  const handleConfirmComplete = () => {
    // Sending Email to parents
    try {
      const recipient_email = user?.email;
      const emailSubject = `${user?.name}, Test Report of ${testDisplay?.subject}`;
      const body = `Total Questions ${numberOfQuestions} 
      Correct Answers ${correctAnswers}
      Incorrect Answers ${numberOfQuestions - correctAnswers}
      `;
      sendEmail({ body, recipient_email, subject: emailSubject });
    } catch (error) {
      console.log("Some error occurred in sending email to parents");
    }

    // Resetting Test Data
    resetTestData();

    // Show the report to the user
    setShowCompletePopup(false);
    setShowReportPopup(true);
  };

  const handleTestReport = () => {
    setshowReportToParent(true);
  };
  const handleReportToParent = () => {
    setShowReportPopup(false);
    navigate("/test-history");
  };

  return (
    <div className={styles.container}>
      <div className={styles.testInfo}>
        <h4 className={styles.timer}>{formatTime(elapsedTime)}</h4> {/* Stopwatch Timer */}
        {testKey !== null && (
          <PrimaryButton
            name="Complete Test"
            onClick={handleCompleteTest}
            backgroundColor="#32cd32"
            mobileFontSize="1.2em"
            marginTop={20}
          />
        )}
      </div>

      {/* If Test Key is set to null from User Context */}
      {testKey === null && (
        <>
          <p>Error generating test, Try Again</p>
          <PrimaryButton name="Create Test Again" onClick={() => navigate(-1)} />
        </>
      )}

      {/* Tests */}
      {testKey !== null && (
        <Test testKey={testKey} testQuestions={testQuestions} isLoading={isLoading} />
      )}

      {/* PopUps */}
      {showCompletePopup && (
        <Popup
          onConfirm={handleConfirmComplete}
          onCancel={() => setShowCompletePopup(false)}
          message="Are you sure you want to complete the test?"
        />
      )}
      {showReportPopup && (
        <TestReportPopup
          onClose={handleTestReport}
          correctAnswers={correctAnswers}
          totalQuestions={numberOfQuestions}
        />
      )}
      {showReportToParent && <ReportToParents onClick={handleReportToParent} />}
    </div>
  );
};

export default TestScreen;
