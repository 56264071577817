import React from "react";

const CreateTestIcon = ({ stroke, width = "22", height = "22" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5714 2H7.14286C4.30253 2 2 4.23857 2 7V42C2 44.7615 4.30253 47 7.14286 47H32.8571C35.6975 47 38 44.7615 38 42V17M22.5714 2L38 17M22.5714 2V14.5C22.5714 15.8807 23.7227 17 25.1429 17H38"
      stroke={stroke}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CreateTestIcon;
