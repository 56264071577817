import React from "react";
import styles from "../../styles/Onboarding.module.css";

const StepOne = ({ name, setName, email, setEmail, mobile, setMobile, onNext }) => {
  const isNextEnabled = name && email && mobile;

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.heading}>Tell us about yourself</h2>
      <input
        type="text"
        placeholder="Write your Name"
        value={name}
        className={styles.topicInput}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Write your Parent's Email"
        value={email}
        className={styles.topicInput}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="text"
        placeholder="Write your Mobile"
        value={mobile}
        className={styles.topicInput}
        onChange={(e) => setMobile(e.target.value)}
      />
      <button
        onClick={onNext}
        disabled={!isNextEnabled}
        className={`${styles.nextButton} ${isNextEnabled ? "" : styles.disabled}`}
      >
        Next
      </button>
    </div>
  );
};

export default StepOne;
