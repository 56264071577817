import React from "react";
import Menu from "../components/Menu";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Settings.module.css";
import { useUserContext } from "../utils/UserContext";
import PrimaryButton from "../components/PrimaryButton";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";
import { deleteLoginCodeLocally } from "../functions/loginCodeFunctions";

const BoxContainer = ({ name = "Name", value = "" }) => {
  return (
    <div className={styles.smallBoxContainer}>
      <div className={styles.name}>{name}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

const Settings = () => {
  const isMobileDevice = useIsMobileDevice();
  const navigate = useNavigate();
  const { user } = useUserContext();

  const logoutUser = () => {
    deleteLoginCodeLocally();
    navigate("/login");
  };

  return (
    <div className={styles.container}>
      <Menu />
      {isMobileDevice && (
        <>
          <Header>
            <h2 className={styles.heading}>Settings</h2>
          </Header>
        </>
      )}
      <div className={styles.allSettings}>
        <div className={styles.boxContainer}>
          <BoxContainer name="Name" value={user?.name} />
          <BoxContainer name="Email" value={user?.email} />
          <BoxContainer name="Mobile Number" value={user?.mobile} />
          <BoxContainer name="Class" value={user?.selectedClass} />
          <BoxContainer name="School" value={user?.school} />
          <BoxContainer name="Understanding Level" value={user?.understandingLevel} />
        </div>
        <PrimaryButton name="Logout" marginTop={40} onClick={logoutUser} />
      </div>
    </div>
  );
};

export default Settings;
