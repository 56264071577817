import React from "react";
import styles from "../../styles/Onboarding.module.css";

const StepThree = ({
  name,
  understandingLevel,
  setUnderstandingLevel,
  onFinish,
  onBack,
  classScore,
  setClassScore,
  futureTarget,
  setFutureTarget,
}) => {
  const levels = ["Weak Student", "Intermediate Student", "Intelligent Student"];
  const target = [
    "I want to Pass this Class",
    "I want to Score more than 70%",
    "I want to Score more than 80%",
    "I want to Score more than 90%",
    "I want to Score more than 95%",
  ];
  const marks = [
    "Less than 50%",
    "Between 50-60%",
    "Between 60-70%",
    "Between 70-80%",
    "Between 80-90%",
    "Between 90-100%",
  ];
  const isFinishEnabled = understandingLevel && classScore && futureTarget;

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.heading}>{name}, Tell us about your Learning Level</h2>
      <select
        value={understandingLevel}
        className={styles.selectDropdown}
        onChange={(e) => setUnderstandingLevel(e.target.value)}
      >
        <option value="" disabled>
          Select Level of Understanding
        </option>
        {levels.map((level) => (
          <option key={level} value={level}>
            {level}
          </option>
        ))}
      </select>
      <select
        value={classScore}
        className={styles.selectDropdown}
        onChange={(e) => setClassScore(e.target.value)}
      >
        <option value="" disabled>
          Select Previous Class Marks
        </option>
        {marks.map((level) => (
          <option key={level} value={level}>
            {level}
          </option>
        ))}
      </select>

      <select
        value={futureTarget}
        className={styles.selectDropdown}
        onChange={(e) => setFutureTarget(e.target.value)}
      >
        <option value="" disabled>
          Select a Target for This Class
        </option>
        {target.map((level) => (
          <option key={level} value={level}>
            {level}
          </option>
        ))}
      </select>

      <div className={styles.navigationButtons}>
        <button className={styles.backButton} onClick={onBack}>
          Back
        </button>
        <button
          onClick={onFinish}
          disabled={!isFinishEnabled}
          className={`${styles.nextButton} ${isFinishEnabled ? "" : styles.disabled}`}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default StepThree;
