import React, { useState } from "react";
import Colors from "../utils/Colors";
import useEnterPressed from "../hooks/useEnterPressed";
import PrimaryButton from "../components/PrimaryButton";
import styles from "../../src/styles/AdminPortal.module.css";
import getFutureTimestamp from "./../functions/getFutureTimestamp";
import { getPushKeyFromFirebase, updateOnFirebase } from "../utils/firebase";

// TODO => Later store them on firebase and fetch here for security
const admins = {
  aiguru: {
    name: "AI Guru",
    password: "admin5896@aiguru",
  },
  studentai: {
    name: "Student AI",
    password: "admin8168@studentai",
  },
  puranmurti: {
    name: "Puran Murti",
    password: "admin7894@puranmurti",
  },
};

const PasswordStep = ({ institute, nextStep }) => {
  const [input, setInput] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const loginHandler = () => {
    if (input === admins[institute]?.password) {
      nextStep();
    } else {
      setShowAlert(true);
    }
  };

  // Handling Enter
  useEnterPressed(loginHandler);

  return (
    <div className={styles.onboardingContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>{admins[institute]?.name} Admin Portal</h2>

        {/* Login */}
        <input
          type="text"
          value={input}
          className={styles.topicInput}
          placeholder="Enter Admin Portal Code"
          onChange={(e) => setInput(e.target.value)}
        />

        {/* Alert */}
        {showAlert && <p style={{ color: Colors.errorColor }}>Invalid Admin Password</p>}

        {/* Login Button */}
        <PrimaryButton
          name="Login"
          width="30%"
          fontSize="1.3em"
          mobileWidth="50%"
          mobileFontSize="1.3em"
          onClick={loginHandler}
        />
      </div>
    </div>
  );
};

const RegisterUser = ({ institute, nextStep, setLoginCode }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [source, setSource] = useState("");
  const isNextEnabled = name && email && mobile;

  const handleSubmit = async () => {
    const today = new Date();
    try {
      const loginCodeFront = getPushKeyFromFirebase({ path: "users" });
      if (!loginCodeFront) {
        throw new Error("Invalid loginCodeFront returned");
      }
      const loginCode = loginCodeFront + "@" + institute;

      const user = {
        name,
        email,
        source,
        mobile,
        loginCode,
        timeStamp: today.getTime(),
        dateString: today.toString(),
      };

      // Update on Firebase Logs
      const updates = {};
      updates[`allowedUser/${loginCode}`] = getFutureTimestamp(3);
      updates[`logs/${institute}/${loginCode}`] = user;
      updateOnFirebase({ updates });

      // Updating Step
      setLoginCode(loginCode);
      nextStep();
    } catch (error) {
      console.log("Some error in generating login code");
    }
  };

  return (
    <div className={styles.onboardingContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>Enter Student's Details</h2>
        <input
          type="text"
          value={name}
          className={styles.topicInput}
          placeholder="Student's Name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          value={email}
          className={styles.topicInput}
          placeholder=" Parent's Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          value={mobile}
          placeholder="Parent's Mobile"
          className={styles.topicInput}
          onChange={(e) => setMobile(e.target.value)}
        />

        <select
          value={source}
          className={styles.selectDropdown}
          onChange={(e) => setSource(e.target.value)}
        >
          <option value="" disabled>
            Select Source
          </option>
          {["Through Teacher", "Direct Student", "Through School"].map((src) => (
            <option key={src} value={src}>
              {src}
            </option>
          ))}
        </select>

        <PrimaryButton
          name="Create Login Code"
          onClick={handleSubmit}
          disabled={!isNextEnabled}
          marginTop={30}
          mobileFontSize="1.2em"
        />
      </div>
    </div>
  );
};

const AdminPortal = ({ institute }) => {
  const [step, setStep] = useState(1);
  const [loginCode, setLoginCode] = useState("");

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const handleCopyCode = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      // Clipboard API supported
      navigator.clipboard
        .writeText(loginCode)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          console.log("Failed to copy text: ", err);
        });
    } else {
      alert("Your browser does not support clipboard copying.");
    }
  };

  return (
    <>
      {step === 1 && <PasswordStep nextStep={nextStep} institute={institute} />}
      {step === 2 && (
        <RegisterUser institute={institute} nextStep={nextStep} setLoginCode={setLoginCode} />
      )}
      {step === 3 && loginCode && (
        <div className={styles.codeScreen}>
          <p>{loginCode}</p>
          <PrimaryButton
            name="Copy Code"
            onClick={handleCopyCode}
            backgroundColor={Colors.menuColor}
          />
          <PrimaryButton
            marginTop={20}
            mobileFontSize="1.2em"
            onClick={() => setStep(2)}
            name="Create New Login Code"
          />
        </div>
      )}
    </>
  );
};

export default AdminPortal;
