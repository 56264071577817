import React from "react";

const ImportantQuestionsIcon = ({
  stroke = "#1b98f5",
  fill = "transparent",
  width = "21",
  height = "21",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1821 4.26904C22.758 2.97562 23.0461 2.32892 23.4472 2.12977C23.7955 1.95674 24.2045 1.95674 24.5529 2.12977C24.9539 2.32892 25.242 2.97562 25.818 4.26904L30.4058 14.5712C30.5762 14.9536 30.6613 15.1448 30.7932 15.2912C30.9096 15.4207 31.0522 15.5243 31.2111 15.5953C31.391 15.6755 31.599 15.6974 32.015 15.7413L43.2215 16.9253C44.6285 17.0739 45.3318 17.1482 45.6451 17.4684C45.917 17.7466 46.0434 18.136 45.9867 18.5211C45.9215 18.9644 45.396 19.4383 44.3451 20.3864L35.9739 27.9375C35.6634 28.2176 35.5079 28.3578 35.4096 28.5284C35.3225 28.6796 35.268 28.8472 35.2499 29.0205C35.2292 29.2165 35.2725 29.4212 35.3593 29.8308L37.6975 40.8646C37.9911 42.25 38.1379 42.9425 37.9304 43.3395C37.75 43.6846 37.4191 43.9252 37.0357 43.9902C36.5944 44.0649 35.9816 43.7113 34.756 43.0038L24.9945 37.3682C24.6322 37.159 24.4511 37.0547 24.2585 37.0136C24.0881 36.9775 23.912 36.9775 23.7415 37.0136C23.549 37.0547 23.3678 37.159 23.0056 37.3682L13.2441 43.0038C12.0186 43.7113 11.4058 44.0649 10.9644 43.9902C10.581 43.9252 10.25 43.6846 10.0698 43.3395C9.86231 42.9425 10.0091 42.25 10.3026 40.8646L12.6407 29.8308C12.7275 29.4212 12.7709 29.2165 12.7503 29.0205C12.7321 28.8472 12.6776 28.6796 12.5906 28.5284C12.4922 28.3578 12.3368 28.2176 12.0261 27.9375L3.65508 20.3864C2.60414 19.4383 2.07865 18.9644 2.01332 18.5211C1.95659 18.136 2.08301 17.7466 2.35504 17.4684C2.66823 17.1482 3.3717 17.0739 4.77864 16.9253L15.9852 15.7413C16.4011 15.6974 16.609 15.6755 16.7889 15.5953C16.948 15.5243 17.0904 15.4207 17.207 15.2912C17.3388 15.1448 17.4239 14.9536 17.5942 14.5712L22.1821 4.26904Z"
      stroke={stroke}
      strokeWidth="4"
      strokeLinecap="round"
      fill={fill}
      strokeLinejoin="round"
    />
  </svg>
);

export default ImportantQuestionsIcon;
