import React, { useState, useEffect } from "react";
import DoubtsChat from "./DoubtsChat";
import Menu from "../../components/Menu";
import EditIcon from "../../assets/EditIcon";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./../../components/Header";
import SubjectSelector from "./SubjectSelector";
import StarQuestionPopup from "./StarQuestionPopup";
import IconButton from "../../components/IconButton";
import { updateOnFirebase } from "../../utils/firebase";
import { useUserContext } from "../../utils/UserContext";
import LottieAnimation from "../../components/LottieAnimation";
import EditSubjectPopup from "../../components/EditSubjectPopup";
import { useIsMobileDevice } from "../../hooks/useIsMobileDevice";
import styles from "../../styles/doubtsStyles/AskDoubts.module.css";
import ImportantQuestionsIcon from "../../assets/ImportantQuestionsIcon";
import DoubtHistoryIcon from "../../assets/DoubtHistoryIcon";

const AskDoubt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { subjects } = useUserContext();
  const isMobileDevice = useIsMobileDevice();
  const [chatKey, setChatKey] = useState("");
  const { conversationsDisplayPath } = useUserContext();
  const [selectedSubject, setSelectedSubject] = useState(null);
  const isSubjectSelected = subjects.includes(selectedSubject);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [isStarPopupOpen, setIsStarPopupOpen] = useState(false);
  const [starIconColor, setStarIconColor] = useState("transparent");
  const [showSubjectAnimation, setShowSubjectAnimation] = useState(false);

  // Getting state from View Test Page if provided
  const { question, testSubject } = location.state || {};

  useEffect(() => {
    if (selectedSubject == null || !isSubjectSelected) {
      console.log("Either subject is null or Not available Now");
      setShowSubjectAnimation(true);
    } else {
      setShowSubjectAnimation(false);
    }
  }, [selectedSubject, isSubjectSelected]);

  // Fetching selected subject from local storage
  useEffect(() => {
    const storedSubject = localStorage.getItem("selectedSubject");
    if (storedSubject) {
      setSelectedSubject(storedSubject);
    }

    if (testSubject) {
      setSelectedSubject(testSubject);
    }
  }, [question, testSubject]);

  useEffect(() => {
    // Setting selected subject from local storage
    if (selectedSubject) {
      localStorage.setItem("selectedSubject", selectedSubject);
    }

    const sessionChatKey = sessionStorage.getItem(selectedSubject);
    if (sessionChatKey) {
      setChatKey(sessionChatKey);
    } else {
      setChatKey("");
    }
  }, [selectedSubject]);

  // Mark As Important
  const markAsImportant = () => {
    if (chatKey) {
      setIsStarPopupOpen(true);
      const updates = { [`${conversationsDisplayPath}/${chatKey}/isImportant`]: true };
      updateOnFirebase({ updates });
      setStarIconColor("#1b98f5");
    }
  };

  return (
    <div className={styles.container}>
      {!isMobileDevice && <Menu />}
      <div className={styles.mainContent}>
        {/* Header Mobile*/}
        {isMobileDevice && (
          <>
            <Header>
              <IconButton
                Logo={EditIcon}
                onClick={() => setEditPopupOpen(true)}
                name="Edit Subjects"
              />
              <div className={styles.history} onClick={() => navigate("/doubts-history")}>
                <DoubtHistoryIcon stroke="#444444" height="18" width="18" />
              </div>
              {isEditPopupOpen && <EditSubjectPopup onClose={() => setEditPopupOpen(false)} />}
            </Header>
            <div className={styles.headerContainer}>
              <SubjectSelector
                selectedSubject={selectedSubject}
                setSelectedSubject={setSelectedSubject}
              />
            </div>
          </>
        )}
        {/* Header Laptop */}
        {!isMobileDevice && (
          <div className={styles.headerContainer}>
            <IconButton Logo={EditIcon} onClick={() => setEditPopupOpen(true)} />
            <SubjectSelector
              selectedSubject={selectedSubject}
              setSelectedSubject={setSelectedSubject}
            />
            <IconButton
              Logo={ImportantQuestionsIcon}
              onClick={markAsImportant}
              fill={starIconColor}
            />
            {isEditPopupOpen && <EditSubjectPopup onClose={() => setEditPopupOpen(false)} />}
            {isStarPopupOpen && <StarQuestionPopup onClose={() => setIsStarPopupOpen(false)} />}
          </div>
        )}

        {/* Not Subject Selected Animation */}
        {showSubjectAnimation && (
          <LottieAnimation
            name="arrow"
            animationStyle={{
              alignSelf: "center",
              width: isMobileDevice ? 100 : 150,
              height: isMobileDevice ? 100 : 150,
              marginTop: isMobileDevice ? 200 : 150,
              marginLeft: isMobileDevice ? -0 : -100,
              transform: "rotate(-110deg) scaleX(-1)",
            }}
          />
        )}

        {/* Text */}
        {showSubjectAnimation && (
          <h4 className={styles.clickToAsk}>Select Subject to ask Doubts & learn Concepts</h4>
        )}

        {/* Message Section */}
        {isSubjectSelected && (
          <DoubtsChat
            chatKey={chatKey}
            setChatKey={setChatKey}
            subject={selectedSubject}
            initialQuestion={question}
          />
        )}
      </div>
    </div>
  );
};

export default AskDoubt;
