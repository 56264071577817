import React, { useEffect, useState } from "react";
import styles from "../styles/PrimaryButton.module.css";
import { getDarkShade } from "../utils/Colors";

const PrimaryButton = ({
  name,
  color,
  onClick,
  height,
  width,
  marginTop,
  hoverColor,
  mobileWidth,
  mobileFontSize,
  disabled = false,
  fontSize = "1.1em",
  backgroundColor = "#007bff",
  ...external
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [finalHoverColor, setFinalHoverColor] = useState();

  // Effect to check if window width is less than 1026px
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1026);
    };

    // Set initial state
    handleResize();

    // Add event listener to update on resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Hover Color
  useEffect(() => {
    if (hoverColor) {
      setFinalHoverColor(hoverColor);
    } else {
      setFinalHoverColor(getDarkShade(backgroundColor));
    }
  }, [hoverColor, backgroundColor]);

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={styles.primaryButton}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height,
        color,
        marginTop,
        width: isMobile ? mobileWidth : width,
        fontSize: isMobile ? mobileFontSize : fontSize,
        backgroundColor: isHovered ? finalHoverColor : backgroundColor,
        ...external,
      }}
    >
      {name}
    </button>
  );
};

export default PrimaryButton;
