import React from "react";
import styles from "../styles/LabelComponent.module.css";

const LabelComponent = ({ label, title, marginTop, marginRight }) => {
  if (label) {
    return (
      <p className={styles.label} style={{ marginTop, marginRight }}>
        {title} {label}
      </p>
    );
  }
};

export default LabelComponent;
