import React from "react";
import Menu from "../components/Menu";
import styles from "../styles/Help.module.css";
import Header from "../components/Header";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";
import LottieAnimation from "../components/LottieAnimation";

const HelpPage = () => {
  const isMobileDevice = useIsMobileDevice();

  return (
    <div className={styles.container}>
      {isMobileDevice && (
        <>
          <Header>
            <h2 className={styles.heading}>Notes</h2>
          </Header>
        </>
      )}
      <div className={styles.mainHavingIssue}>
        <LottieAnimation
          name="comingSoon"
          animationStyle={{ width: 200, height: 200, alignSelf: "center" }}
        />
      </div>
    </div>
  );
};

const TopperNotes = () => {
  return (
    <div className={styles.container}>
      <Menu />
      <HelpPage />
    </div>
  );
};

export default TopperNotes;
