import React from "react";

const SettingsIcon = ({ stroke }) => (
  <svg width="22" height="22" viewBox="0 0 70 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 27.9201V49.0767C2 56.85 2 56.85 9.33333 61.8001L29.5 73.4601C32.5433 75.2201 37.4933 75.2201 40.5 73.4601L60.6667 61.8001C68 56.85 68 56.85 68 49.1134V27.9201C68 20.1834 68 20.1834 60.6667 15.2334L40.5 3.57342C37.4933 1.81342 32.5433 1.81342 29.5 3.57342L9.33333 15.2334C2 20.1834 2 20.1834 2 27.9201Z"
      stroke={stroke}
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SettingsIcon;
