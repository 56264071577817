import React, { useState, useEffect } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/Onboarding.module.css";
import { updateOnFirebase } from "../../utils/firebase";
import { useUserContext } from "../../utils/UserContext";
import useEnterPressed from "../../hooks/useEnterPressed";
import BrandIdentity from "../../components/BrandIdentity";
import { getLoginCodeLocally } from "../../functions/loginCodeFunctions";

const Onboarding = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const loginCode = getLoginCodeLocally();
  const { user, subjects } = useUserContext();

  // User Variables
  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [school, setSchool] = useState("");
  const [classScore, setClassScore] = useState("");
  const [futureTarget, setFutureTarget] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [understandingLevel, setUnderstandingLevel] = useState("");

  useEffect(() => {
    if (user) {
      user?.name && setName(user.name);
      user?.email && setEmail(user.email);
      user?.mobile && setMobile(user.mobile);
      user?.school && setSchool(user.school);
      user?.classScore && setClassScore(user.classScore);
      user?.futureTarget && setFutureTarget(user.futureTarget);
      user?.selectedClass && setSelectedClass(user.selectedClass);
      user?.understandingLevel && setUnderstandingLevel(user.understandingLevel);
    }
  }, [user]);

  const mappingSubjects = (arr) => Object.fromEntries(arr.map((item) => [item, true]));

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  // Handle Submit
  const handleFinish = () => {
    const userInfo = {
      name,
      email,
      mobile,
      school,
      loginCode,
      classScore,
      futureTarget,
      selectedClass,
      understandingLevel,
      subjects: mappingSubjects(subjects),
    };
    const updates = { [`users/${loginCode}`]: userInfo };
    updateOnFirebase({ updates });
    navigate("/");
  };

  // Next Button
  const handleNext = () => {
    if (
      (step === 1 && name && email && mobile) ||
      (step === 2 && school && selectedClass && subjects)
    ) {
      nextStep();
    } else if (step === 3 && understandingLevel && classScore && futureTarget) {
      handleFinish();
    }
  };

  // Going to next if enter pressed
  useEnterPressed(handleNext);

  return (
    <div className={styles.onboardingContainer}>
      <BrandIdentity />
      {step === 1 && (
        <StepOne
          name={name}
          email={email}
          mobile={mobile}
          setName={setName}
          setEmail={setEmail}
          setMobile={setMobile}
          onNext={nextStep}
        />
      )}
      {step === 2 && (
        <StepTwo
          name={name}
          school={school}
          onNext={nextStep}
          onBack={prevStep}
          setSchool={setSchool}
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
        />
      )}
      {step === 3 && (
        <StepThree
          name={name}
          onBack={prevStep}
          classScore={classScore}
          onFinish={handleFinish}
          futureTarget={futureTarget}
          setClassScore={setClassScore}
          setFutureTarget={setFutureTarget}
          understandingLevel={understandingLevel}
          setUnderstandingLevel={setUnderstandingLevel}
        />
      )}
    </div>
  );
};

export default Onboarding;
