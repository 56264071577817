import React from "react";
import styles from "../../styles/doubtsStyles/StarQuestionPopup.module.css";
import PrimaryButton from "../../components/PrimaryButton";

const StarQuestionPopup = ({ onClose }) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <h4 className={styles.heading}>Your question has been marked as important</h4>
        <PrimaryButton name="Done" onClick={onClose} marginTop={25} width="40%" />
      </div>
    </div>
  );
};

export default StarQuestionPopup;
