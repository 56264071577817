import React from "react";
import styles from "../styles/Feedback.module.css";
import Menu from "../components/Menu";
import PrimaryButton from "../components/PrimaryButton";
import Header from "../components/Header";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";

const Feedback = () => {
  const isMobileDevice = useIsMobileDevice();
  const whatsappUrl = "https://wa.me/918168278142?text=Write%20your%20feedback%20here...";

  return (
    <div className={styles.container}>
      <Menu />
      {isMobileDevice && (
        <>
          <Header>
            <h2 className={styles.heading}>Feedback</h2>
          </Header>
        </>
      )}
      <div className={styles.mainPage}>
        <PrimaryButton
          name="Give Feedback on Whatsapp"
          backgroundColor="#32cd32"
          mobileWidth="80%"
          marginTop={30}
          onClick={() => window.open(whatsappUrl, "_blank")}
        />
      </div>
    </div>
  );
};

export default Feedback;
