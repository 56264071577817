const getFutureTimestamp = (numberOfDays) => {
  // Get the current date
  const currentDate = new Date();

  // Add the number of days to the current date
  currentDate.setDate(currentDate.getDate() + numberOfDays);

  // Return the timestamp (in milliseconds)
  return currentDate.getTime();
};

export default getFutureTimestamp;
// console.log(getFutureTimestamp(3));
