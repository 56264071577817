import React, { useState } from "react";
import styles from "../../styles/Onboarding.module.css";
import EditSubjectPopup from "./../../components/EditSubjectPopup";
import LabelComponent from "../../components/LabelComponent";
import { useUserContext } from "../../utils/UserContext";

const StepTwo = ({ name, school, onNext, onBack, setSchool, selectedClass, setSelectedClass }) => {
  const { subjects } = useUserContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const classes = [
    "Class 1",
    "Class 2",
    "Class 3",
    "Class 4",
    "Class 5",
    "Class 6",
    "Class 7",
    "Class 8",
    "Class 9",
    "Class 10",
    "Class 11",
    "Class 12",
    "B.Tech",
    "M.Tech",
    "BSC",
    "MSC",
    "Graduate",
  ];

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const isNextEnabled = school && selectedClass && subjects.length > 0;

  // Helper to format the label text
  const getFormattedLabel = () => {
    if (subjects.length <= 3) {
      return subjects.join(", ");
    }
    const displayedSubjects = subjects.slice(0, 3).join(", ");
    const remainingCount = subjects.length - 3;
    return `${displayedSubjects} +${remainingCount} more`;
  };

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.heading}>{name}, Tell us about your education</h2>
      <input
        type="text"
        placeholder="Write your School Name"
        value={school}
        className={styles.topicInput}
        onChange={(e) => setSchool(e.target.value)}
      />
      <select
        value={selectedClass}
        className={styles.selectDropdown}
        onChange={(e) => setSelectedClass(e.target.value)}
      >
        <option value="" disabled>
          Select Class
        </option>
        {classes.map((cls) => (
          <option key={cls} value={cls}>
            {cls}
          </option>
        ))}
      </select>
      <button className={styles.selectDropdown} onClick={openPopup}>
        Select Subjects
      </button>

      {/* Display selected subjects in limited format */}
      <LabelComponent label={getFormattedLabel()} marginRight={0} marginTop={20} />

      <div className={styles.navigationButtons}>
        <button className={styles.backButton} onClick={onBack}>
          Back
        </button>
        <button
          onClick={onNext}
          disabled={!isNextEnabled}
          className={`${styles.nextButton} ${isNextEnabled ? "" : styles.disabled}`}
        >
          Next
        </button>
      </div>

      {isPopupOpen && <EditSubjectPopup onClose={closePopup} />}
    </div>
  );
};

export default StepTwo;
