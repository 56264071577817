import React from "react";
import { useLocation } from "react-router-dom";
import styles from "../../styles/testStyles/ViewPreviousExam.module.css";
import PrimaryButton from "../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ViewPreviousExam = () => {
  const location = useLocation();
  const { examUrl } = location.state || {};
  const navigate = useNavigate();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleBackClick = () => {
    navigate("/previous-year-exams");
  };

  return (
    <div className={styles.container}>
      <PrimaryButton name="Back" width="15%" marginTop={10} onClick={handleBackClick} />
      {examUrl ? (
        <div className={styles.pdfViewerContainer}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
            <Viewer
              fileUrl={examUrl}
              plugins={[defaultLayoutPluginInstance]}
              className={styles.pdfViewer}
            />
          </Worker>
        </div>
      ) : (
        <p>No exam selected.</p>
      )}
    </div>
  );
};

export default ViewPreviousExam;
