import React, { useEffect, useRef } from "react";
import { useUserContext } from "../utils/UserContext";
import styles from "../styles/EditSubjectPopup.module.css";
import { removeFromFirebase, updateOnFirebase } from "../utils/firebase";

const allSubjects = [
  "English",
  "Mathematics",
  "Science",
  "Hindi",
  "Social Science",
  "History",
  "Geography",
  "Physics",
  "Chemistry",
  "Biology",
  "Computer Science",
  "Economics",
  "Business Studies",
  "Accountancy",
  "Physical Education",
  "Sanskrit",
  "Fine Arts",
  "Home Science",
  "Music",
  "Information Technology",
];

const EditSubjectPopup = ({ onClose }) => {
  const { userPath, subjects } = useUserContext();
  const popupRef = useRef(null);

  // Close popup if clicked outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  // Add a subject to user
  const addSubject = (subject) => {
    const updates = { [`${userPath}/subjects/${subject}`]: true };
    updateOnFirebase({ updates });
  };

  // Remove subject from user
  const removeSubject = (subject) => {
    removeFromFirebase({ path: `${userPath}/subjects/${subject}` });
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup} ref={popupRef}>
        <div className={styles.header}>
          <h4 className={styles.heading}>Edit Subjects</h4>
          <button className={styles.closeButton} onClick={onClose}>
            ✖
          </button>
        </div>
        <div className={styles.subjectList}>
          {allSubjects.map((subject) => (
            <div key={subject} className={styles.subjectRow}>
              <span className={styles.subjectName}>{subject}</span>
              <button
                className={subjects.includes(subject) ? styles.removeButton : styles.addButton}
                onClick={() => {
                  if (subjects.includes(subject)) {
                    removeSubject(subject);
                  } else {
                    addSubject(subject);
                  }
                }}
              >
                {subjects.includes(subject) ? "Remove" : "Add"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditSubjectPopup;
