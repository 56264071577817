import Menu from "../components/Menu";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../functions/isEmpty";
import { useUserContext } from "../utils/UserContext";
import styles from "../styles/TestHistory.module.css";
import { useSyncWithFirebase } from "../utils/firebase";
import PrimaryButton from "../components/PrimaryButton";
import LabelComponent from "../components/LabelComponent";
import Header from "./../components/Header";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";

const Test = ({ testDisplay, testKey }) => {
  const navigate = useNavigate();

  // Function to handle the button click
  const handleViewTest = () => {
    navigate("/view-test", { state: { testDisplay, testKey } });
  };

  return (
    <div className={styles.testContainer}>
      <h3 className={styles.topicName}>{testDisplay.topic}</h3>
      <div className={styles.testInfo}>
        <div className={styles.infoRow}>
          <LabelComponent label={testDisplay.subject} />
          <LabelComponent label={testDisplay.difficulty} />
          <LabelComponent label={testDisplay.questionType} />
          <LabelComponent label={`${testDisplay.numberOfQuestions} Questions`} />
        </div>
      </div>
      <PrimaryButton
        name="View Test Answers"
        onClick={handleViewTest}
        width="32%"
        mobileWidth="70%"
      />
    </div>
  );
};

const TestHistory = () => {
  const navigate = useNavigate();
  const { testsDisplayPath } = useUserContext();
  const [allTests, setAllTests] = useState({});
  const isMobileDevice = useIsMobileDevice();

  useSyncWithFirebase({ path: testsDisplayPath, setFunction: setAllTests });

  return (
    <div className={styles.container}>
      <Menu />
      {isMobileDevice && (
        <>
          <Header screen="/">
            <h2 className={styles.heading}>Tests History</h2>
          </Header>
        </>
      )}
      <div className={styles.mainPage}>
        <div className={styles.allTests}>
          {!isEmpty(allTests) ? (
            Object.entries(allTests)
              .reverse()
              .map(([key, testDisplay]) => (
                <Test key={key} testDisplay={testDisplay} testKey={key} />
              ))
          ) : (
            <PrimaryButton
              marginTop={25}
              width="30%"
              mobileWidth="70%"
              mobileFontSize="1.1em"
              name="Create your First Test"
              onClick={() => navigate("/mock-test")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TestHistory;
