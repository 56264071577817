import React, { useEffect } from "react";
import styles from "../styles/Home.module.css";
import { useNavigate } from "react-router-dom";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";
import BrandIdentity from "../components/BrandIdentity";
import LottieAnimation from "../components/LottieAnimation";
import DoubtIcon from "./../assets/DoubtIcon";
import CreateTestIcon from "../assets/CreateTestIcon";
import ImportantQuestionsIcon from "./../assets/ImportantQuestionsIcon";
import MenuItem from "../components/MenuItem";
import HelpIcon from "../assets/Help";
import FeedbackIcon from "../assets/FeedbackIcon";
import SettingsIcon from "../assets/SettingsIcon";
import { useUserContext } from "../utils/UserContext";
import { giveHapticFeedback } from "./../functions/giveHapticFeedback";
import NotesIcon from "../assets/NotesIcon";

const menuItems = [
  {
    name: "Doubts History",
    path: "/doubts-history",
    icon: DoubtIcon,
    section: "History",
  },
  {
    name: "Tests History",
    path: "/test-history",
    icon: CreateTestIcon,
    section: "History",
  },
  {
    name: "Help & Support",
    path: "/help",
    icon: HelpIcon,
    section: "General",
  },
  {
    name: "Give Feedback",
    path: "/feedback",
    icon: FeedbackIcon,
    section: "General",
  },
  {
    name: "Settings",
    path: "/settings",
    icon: SettingsIcon,
    section: "General",
  },
];

export const FeatureFrame = ({ color = "red", name, text, icon, onClick }) => {
  const handleClick = () => {
    giveHapticFeedback();
    if (onClick) onClick();
  };

  return (
    <div className={styles.horizontalToolContainer} onClick={handleClick}>
      <div className={styles.boxContainer}>
        <div className={styles.box} style={{ backgroundColor: color }}>
          {icon}
        </div>
      </div>
      <div className={styles.toolDescriptionContainer}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.description}>{text}</p>
      </div>
    </div>
  );
};

const Home = () => {
  const sections = ["History", "General"];
  const navigate = useNavigate();
  const isMobileDevice = useIsMobileDevice();
  const { user } = useUserContext();

  const handleMenuClick = (item) => {
    giveHapticFeedback();
    navigate(item.path);
  };

  useEffect(() => {
    if (!isMobileDevice) {
      navigate("/ask-doubt");
    }
  }, [isMobileDevice, navigate]);

  if (!isMobileDevice) return null;

  return (
    <div className={styles.container}>
      {/* Header */}
      <div className={styles.header}>
        <div className={styles.lottieBackground}>
          <LottieAnimation
            name="gradient"
            speed={0.5}
            animationStyle={{ height: "100%", width: "100%" }}
          />
          <div className={styles.overlay}></div>
        </div>

        <div className={styles.leftHeader}>
          <div className={styles.brandBox}>
            <BrandIdentity
              marginBottom={0}
              paddingTop={5}
              paddingLeft={0}
              height={34}
              width={34}
              marginRight={10}
              fontSize="1.25em"
              color="#d2edff"
            />
          </div>
          <h3 className={styles.heading}>AI Education for Smart Students</h3>
        </div>

        <div className={styles.rightHeader}>
          <LottieAnimation
            name="study"
            animationStyle={{
              height: 150,
              width: 150,
              backgroundColor: "transparent",
            }}
          />
        </div>
      </div>
      {/* Body */}
      <div className={styles.body}>
        <h4 className={styles.titleName}>Hi {user?.name}, What do you want to do ?</h4>
        {/* Main Features */}
        <FeatureFrame
          name="Ask Doubts & Concept"
          icon={<DoubtIcon stroke="white" height="30" width="30" />}
          color="#6050DC"
          onClick={() => navigate("/ask-doubt")}
          text={
            <>
              Ask doubts and get accurate <br /> solutions instantly
            </>
          }
        />
        <FeatureFrame
          name="Create & Solve Tests"
          icon={<CreateTestIcon stroke="white" height="30" width="30" />}
          color="#32CD32"
          onClick={() => navigate("/test")}
          text="Create a test and make a weak topic strong "
        />
        <FeatureFrame
          name="Previous Year Exams"
          icon={<ImportantQuestionsIcon stroke="white" height="30" width="30" />}
          color="#FF6700"
          onClick={() => navigate("/previous-year-exams")}
          text="Solve real exam problems to gain confidence"
        />
        <FeatureFrame
          name="Topper's Notes"
          icon={<NotesIcon stroke="white" height="50" width="50" />}
          // icon={<ImportantQuestionsIcon stroke="white" height="30" width="30" />}
          color="#F4C430"
          onClick={() => navigate("/topper-notes")}
          text="Handwritten Notes of CBSE Topper's"
        />

        {/* Items */}
        <div className={styles.items}>
          {sections.map((section) => (
            <div key={section}>
              <p className={styles.quick}>{section}</p>
              {menuItems
                .filter((item) => item.section === section)
                .map((item) => (
                  <MenuItem
                    key={item.name}
                    name={item.name}
                    IconComponent={item.icon}
                    onClick={() => handleMenuClick(item)}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
