import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";

const LottieAnimation = ({ name, animationStyle, loop = true, autoplay = true, speed = 1 }) => {
  const animationRef = useRef(null);
  let animationData = null;

  try {
    animationData = require(`../assets/animations/${name}.json`);
  } catch (error) {
    console.error(`Animation not found: ${name}.json`, error);
  }

  // Set the animation speed in useEffect
  useEffect(() => {
    if (animationRef.current && animationData) {
      animationRef.current.setSpeed(speed);
    }
  }, [speed, animationData]);

  // Render only if animationData is available
  if (!animationData) return null;

  return (
    <Lottie
      lottieRef={animationRef} // Attach the ref to the Lottie instance
      loop={loop}
      autoplay={autoplay}
      style={animationStyle}
      animationData={animationData}
    />
  );
};

export default LottieAnimation;
