import Menu from "../components/Menu";
import { isEmpty } from "../functions/isEmpty";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../utils/UserContext";
import { useSyncWithFirebase } from "../utils/firebase";
import styles from "../styles/DoubtsHistory.module.css";
import PrimaryButton from "../components/PrimaryButton";
import LabelComponent from "../components/LabelComponent";
import Header from "../components/Header";
import { useIsMobileDevice } from "../hooks/useIsMobileDevice";

const DoubtsHistory = () => {
  const isMobileDevice = useIsMobileDevice();
  const navigate = useNavigate();
  const { conversationsDisplayPath } = useUserContext();
  const [conversations, setConversations] = useState({});
  useSyncWithFirebase({ path: conversationsDisplayPath, setFunction: setConversations });

  const conversationsArray = !isEmpty(conversations) ? Object.values(conversations).reverse() : [];
  // const importantArray = conversationsArray.filter((conv) => conv.isImportant == true);
  // console.log(importantArray);

  const handleViewQuestion = (conversation) => {
    navigate("/question-display", { state: { conversation } });
  };

  return (
    <div className={styles.container}>
      <Menu />
      {isMobileDevice && (
        <>
          <Header>
            <h2 className={styles.heading}>Doubts History</h2>
          </Header>
        </>
      )}
      <div className={styles.mainPage}>
        {isEmpty(conversations) ? (
          <PrimaryButton
            marginTop={25}
            width="30%"
            mobileWidth="70%"
            mobileFontSize="1.1em"
            onClick={() => navigate("/ask-doubt")}
            name="Start by asking Questions"
          />
        ) : (
          conversationsArray.map((conversation) => (
            <div key={conversation?.key} className={styles.questionItem}>
              <div className={styles.textContainer}>
                <p className={styles.questionText}>{conversation.title}</p>
                <div className={styles.subjectContainer}>
                  <LabelComponent label={conversation.subject} />
                  {/* <p className={styles.subjectText}>{conversation.subject}</p> */}
                  {conversation.isImportant && <LabelComponent label="Important" />}
                </div>
              </div>
              <PrimaryButton
                name="View Question"
                width="25%"
                mobileWidth="70%"
                onClick={() => handleViewQuestion(conversation)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DoubtsHistory;
